import { telematicsConstants } from "../actions/types/telematics.constants";

export function TelematicsList(state = {}, actions) {
    switch (actions.type) {
        case telematicsConstants.GET_TELEMATICS_REQUEST:
            return {
                telematics_loading: true,
                data: null,
            };
        case telematicsConstants.GET_TELEMATICS_SUCCESS:
            return {
                data: actions.telematics_list.data,
                telematics_data:
                    actions.telematics_list.data["telematics_data"],
                telematics_company: actions.telematics_list.data["company"],
                telematics_foreman: actions.telematics_list.data["foreman"],
                telematics_machine: actions.telematics_list.data["machine"],
                no_of_pages: actions.telematics_list.data["no_of_pages"],
                telematics_loading: false,
            };
        case telematicsConstants.GET_TELEMATICS_FAILURE:
            return {
                telematics_loading: false,
                data: null,
            };
        default:
            return state;
    }
}

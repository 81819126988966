import { useState, useEffect, useRef } from "react";
import NavBar from "./NavBar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { documentActions } from "../store/actions/document.actions";
import { MDBBtn } from "mdb-react-ui-kit";
import quote from "../assets/icons/quote_icon.svg";
import timesheet from "../assets/icons/timesheet_icon.svg";
import po from "../assets/icons/po_icon.svg";
import invoice from "../assets/icons/invoice_icon.svg";
import po2 from "../assets/icons/image.png";
import pOPrder from "../assets/icons/pOrder.png";

import DocumentpopUp from "./documentPopUp";
import PaginationCustom from "./PaginationCustom2";
import PaginationPageNum from "./PaginationPageNum2";
import "react-month-picker/css/month-picker.css";
import SelectOptionDropDown from "./SelectOptionDropDown";
import moment from "moment";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import { Placeholder } from "react-bootstrap";
import { handleLongString } from "../helpers/Helper";
import DocumentPopUp from "./secondDocumentPopup";
import SideBar from "./SideBar";
import PageTitleSearch from "./PageTitleSearch";
import DocumentDropdowns from "./documentDropdowns";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/24/outline";
import { calenderDays } from "../config/config";
import PrimaryBtn from "./PrimaryBtn";
import docTypeIcon from "../assets/images/document-type.svg";
import macTypeIcon from "../assets/images/machine-type.svg";
import yearIcon from "../assets/images/year.svg";
import { Modal } from "../components/modals/finance";
import { DocumentType } from "../components/DocumentSelectors/documentType";
import { MachineType } from "../components/DocumentSelectors/machineType";
// import { SelectedDate } from "../components/DocumentSelectors/selectedDat.jsx";
import { Year } from "../components/DocumentSelectors/year";
import { DocumentContractor } from "./DocumentSelectors/contractor";

function ViewDocument() {
  const user_dt = useSelector((state) => state.users);

  const [open, setOpen] = useState(false);

  const checkbox = useRef(null);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : doc_data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  const openDocumentModal = (document_data) => {
    setOpen(true);
    setDocumentdata(document_data);
  };

  const title = "Financial Data";
  const subtitle = "Here's an overview of your Finance data";

  const user_role =
    user_dt.items !== undefined && user_dt.items["role_detail"]["role"];

  const [reset, setReset] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [placeholder, setplaceholder] = useState("Date");

  const dateRangePickerRef = useRef(null);

  const handleReset = () => {
    const { current: picker } = dateRangePickerRef;
    if (picker) {
      picker.setStartDate(startDate);
      picker.setEndDate(today);
    }
  };

  const handleCallback = (start, end, label) => {
    setPage(1);
    let s_date = null;
    let e_date = null;

    s_date = moment(start).format("YYYY-MM-DD");
    setplaceholder(
      `${moment(start).format("YYYY-MM-DD")}~${moment(end).format(
        "YYYY-MM-DD"
      )} `
    );
    localStorage.setItem("start_date", moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DDY"));
    e_date = moment(end).format("YYYY-MM-DD");
    localStorage.setItem("end_date", moment(end).format("YYYY-MM-DD"));
    dispatch(
      documentActions.getDocumentlist(
        localStorage.getItem("file_type")
          ? localStorage.getItem("file_type")
          : null,
        localStorage.getItem("docComapny")
          ? localStorage.getItem("docComapny")
          : null,
        localStorage.getItem("foreman")
          ? localStorage.getItem("foreman")
          : null,
        localStorage.getItem("doc-machine")
          ? localStorage.getItem("doc-machine")
          : null,
        1,
        s_date,
        e_date,
        localStorage.getItem("year") ? localStorage.getItem("year") : null,
        localStorage.getItem("doc_search")
          ? localStorage.getItem("doc_search")
          : null
      )
    );
  };

  const dispatch = useDispatch();
  const doc_type_list = useSelector(
    (state) => state.DocumentList.doc_type_list
  );
  const mach_type_list = useSelector((state) => state.DocumentList.mach_list);

  const total = useSelector((state) => state.DocumentList.total);
  const offset = useSelector((state) => state.DocumentList.offset);
  const upto = useSelector((state) => state.DocumentList.upto);
  const mach_sub_type_list = useSelector(
    (state) => state.DocumentList.mach_sub_list
  );
  const hire_type_list = useSelector((state) => state.DocumentList.hire_list);

  // const machineList = useSelector((state) => state.DocumentList.machine);
  const companyList = [
    {
      label: "Red Top Asset Management",
      value: "Red Top Asset Management",
      type: "docComapny",
    },
    {
      label: "Quartz Plant Hire",
      value: "Quartz Plant Hire",
      type: "docComapny",
    },
    {
      label: "KLT Machinery and Plant Hire",
      value: "KLT Machinery and Plant Hire",
      type: "docComapny",
    },
  ];
  // const foremanList = useSelector((state) => state.DocumentList.foreman);
  const foremanList = [];
  const machineList = [];

  // const yearList = useSelector((state) => state.DocumentList.yearList);
  const yearList = [
    { label: 2021, value: "2021", type: "year" },
    { label: 2022, value: "2022", type: "year" },
    { label: 2023, value: "2023", type: "year" },
    { label: 2024, value: "2024", type: "year" },

  ];
  const no_of_pages = useSelector((state) => state.DocumentList.no_of_pages);
  const doc_data = useSelector((state) => state.DocumentList.data);
  console.log("hereee", doc_data)


  // console.log("doc list main", doc_data)
  const data_loading = useSelector(
    (state) => state.DocumentList.document_loading
  );
  const [searchValue, setSearchValue] = useState(null);
  const [searchItem, setSearchitem] = useState("");
  const [selectdMonth, setSelectdMonth] = useState(null);
  const [fileType, setFiletype] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [foremanType, setForemanType] = useState(null);
  const [machineType, setMachineType] = useState(null);
  const [documentdata, setDocumentdata] = useState(null);
  const [documentPopUp, setDocumentPopUp] = useState(false);
  const openDocumentPopUp = (document_data) => {
    setDocumentPopUp(true);
    setDocumentdata(document_data);
    localStorage.setItem("m-popup", true);
  };
  const hideDocumentPopUp = () => {
    setDocumentPopUp(false);
  };

  const [page, setPage] = useState(1);
  const updatePageNo = (newState) => {
    setPage(newState);
  };

  const handlePageChange2 = (pageNo) => {
    setPage(pageNo);
    dispatch(
      documentActions.getDocumentlist(
        localStorage.getItem("file_type")
          ? localStorage.getItem("file_type")
          : null,
        localStorage.getItem("docComapny")
          ? localStorage.getItem("docComapny")
          : null,
        foremanType,
        localStorage.getItem("doc-machine")
          ? localStorage.getItem("doc-machine")
          : null,
        pageNo,
        localStorage.getItem("start_date")
          ? localStorage.getItem("start_date")
          : null,
        localStorage.getItem("end_date")
          ? localStorage.getItem("end_date")
          : null,
        localStorage.getItem("year") ? localStorage.getItem("year") : null,
        localStorage.getItem("doc_search")
          ? localStorage.getItem("doc_search")
          : null
      )
    );
  };

  const renderPageNumbers = () => {
    const elements = [];
    let new_no_of_pages = 1;
    let page_start = 1;
    if (no_of_pages > 4) {
      page_start = page;
      new_no_of_pages += 2 + page;
      if (new_no_of_pages >= no_of_pages) {
        new_no_of_pages = no_of_pages;
      }
      let page_diff = no_of_pages - page_start;

      if (page_diff <= 3) {
        page_start = no_of_pages - 4;
      }
    } else {
      new_no_of_pages = no_of_pages;
    }

    for (let i = page_start; i <= new_no_of_pages; i++) {
      elements.push(
        <PaginationPageNum
          key={i}
          active={page === i}
          onClick={() => handlePageChange2(i)}
        >
          {i}
        </PaginationPageNum>
      );
    }
    return elements;
  };
  const handlePageChange = (pageNo) => {
    if (pageNo === "-") {
      dispatch(
        documentActions.getDocumentlist(
          localStorage.getItem("file_type")
            ? localStorage.getItem("file_type")
            : null,
          localStorage.getItem("docComapny")
            ? localStorage.getItem("docComapny")
            : null,
          foremanType,
          localStorage.getItem("doc-machine")
            ? localStorage.getItem("doc-machine")
            : null,
          page - 1,
          localStorage.getItem("start_date")
            ? localStorage.getItem("start_date")
            : null,
          localStorage.getItem("end_date")
            ? localStorage.getItem("end_date")
            : null,
          localStorage.getItem("year") ? localStorage.getItem("year") : null,
          localStorage.getItem("doc_search")
            ? localStorage.getItem("doc_search")
            : null
        )
      );
    }

    if (pageNo === "+") {
      dispatch(
        documentActions.getDocumentlist(
          localStorage.getItem("file_type")
            ? localStorage.getItem("file_type")
            : null,
          localStorage.getItem("docComapny")
            ? localStorage.getItem("docComapny")
            : null,
          foremanType,
          localStorage.getItem("doc-machine")
            ? localStorage.getItem("doc-machine")
            : null,
          page + 1,
          localStorage.getItem("start_date")
            ? localStorage.getItem("start_date")
            : null,
          localStorage.getItem("end_date")
            ? localStorage.getItem("end_date")
            : null,
          localStorage.getItem("year") ? localStorage.getItem("year") : null,
          localStorage.getItem("doc_search")
            ? localStorage.getItem("doc_search")
            : null
        )
      );
    }
  };

  const handleclosepopup = (rslt) => {
    setDocumentPopUp(rslt);
  };


  const today = new Date();
  // const startDate = new Date();
  // startDate.setDate(today.getDate() - calenderDays);
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1);


  const [dateRange, setDateRange] = useState({
    startDate,
    endDate: today,
    maxDate: today,
  });

  const HandleSearchChange2 = (search) => {
    setSearchitem(search);
    setSearchValue(search);
  };
  const HandleSearchChange = (search) => {
    localStorage.setItem("doc_search", search);
    setSearchitem(search);
    setPage(1);
    setSearchValue(search);

    dispatch(
      documentActions.getDocumentlist(
        localStorage.getItem("file_type")
          ? localStorage.getItem("file_type")
          : null,
        localStorage.getItem("docComapny")
          ? localStorage.getItem("docComapny")
          : null,
        localStorage.getItem("foreman")
          ? localStorage.getItem("foreman")
          : null,
        localStorage.getItem("doc-machine")
          ? localStorage.getItem("doc-machine")
          : null,
        1,
        localStorage.getItem("start_date")
          ? localStorage.getItem("start_date")
          : null,
        localStorage.getItem("end_date")
          ? localStorage.getItem("end_date")
          : null,
        localStorage.getItem("year") ? localStorage.getItem("year") : null,
        localStorage.getItem("doc_search")
          ? localStorage.getItem("doc_search")
          : null
      )
    );
  };

  const HandleClearFilters = () => {
    handleReset();
    setDateRange({
      startDate,
      endDate: today,
      maxDate: today,
    });
    setReset(!reset);
    setplaceholder("Date");
    HandleSearchChange2(" ");
    localStorage.removeItem("doc_search");
    localStorage.removeItem("doc_search");
    localStorage.removeItem("doc-machine");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("file_type");
    localStorage.removeItem("year");
    localStorage.removeItem("docComapny");
    handleCallback(startDate, today);
  };
  useEffect(() => {
    // dispatch(
    //   documentActions.getDocumentlist(
    //     fileType,
    //     companyType,
    //     foremanType,
    //     machineType,
    //     page,
    //     selectdMonth
    //   )
    // );

    localStorage.removeItem("doc_search");
    localStorage.removeItem("doc-machine");
    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
    localStorage.removeItem("file_type");
    localStorage.removeItem("year");
    localStorage.removeItem("docComapny");
    handleCallback(startDate, today);
    setReset(false);
  }, []);
  return (
    <div className="flex">
      <Modal open={open} setOpen={setOpen} document_data={documentdata} />
      <SideBar />
      <main className="w-full min-w-[70rem] overflow-x-auto bg-gray-100 py-10">
        <div>
          {/* <PageTitleSearch /> */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="lg:flex lg:items-center">
                <div className="space-y-4 sm:flex-auto">
                  <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                    Finance Data
                  </h1>
                  <p className="mt-2 text-sm text-gray-500">
                    Here's an overview of your Finance Data
                  </p>
                </div>

                <div className="mt-4 lg:ml-16 lg:mt-0 lg:w-3/4 lg:flex-none">
                  <div>
                    <div className="w-full max-w-xs lg:ml-auto">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Search"
                          type="search"
                          onChange={(event) =>
                            HandleSearchChange(event.target.value)
                          }
                          value={searchValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <DocumentDropdowns
                  doc_type_list={doc_type_list && doc_type_list}
                  mach_type_list={mach_type_list && mach_type_list}
                  yearList={yearList && yearList}
                /> */}

              <div className="my-5 flex items-center space-x-5">
                {user_role &&
                  (user_role === "Approver" || user_role === "CFO" ? (
                    <DocumentContractor
                      contractorList={companyList && companyList}
                      updatePageNo={updatePageNo}
                    />
                  ) : null)}
                <DocumentType
                  doc_type={doc_type_list && doc_type_list}
                  updatePageNo={updatePageNo}
                />
                <MachineType
                  mach_type_list={mach_type_list && mach_type_list}
                />
                <Year
                  year_list={yearList && yearList}
                  updatePageNo={updatePageNo}
                />
                <div className="date-range ">
                  <DateRangePicker
                    ref={dateRangePickerRef}
                    onCallback={handleCallback}
                    initialSettings={dateRange}
                  // ref={dateRangePickerRef}
                  // startDate={selectedRange ? selectedRange.startDate : null}
                  // endDate={selectedRange ? selectedRange.endDate : null}
                  // onApply={handleCallback}
                  >
                    <button
                      type="button"
                      className="custom-select-container_calendar "
                    >
                      {handleLongString(placeholder, 12)}
                    </button>
                  </DateRangePicker>
                </div>
                {/* <SelectedDate /> */}
                <button
                  onClick={() => HandleClearFilters()}
                  type="button"
                  className="mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-[#FCAF17] px-5 py-[0.45rem] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={14}
                    fill="none"
                  >
                    <path
                      fill="#fff"
                      d="M5.25 14c-1.486-.194-2.733-.85-3.74-1.969C.503 10.913 0 9.583 0 8.041c0-.847.16-1.635.48-2.364.319-.73.756-1.365 1.312-1.906l1.062 1.062a4.464 4.464 0 0 0-.99 1.438A4.37 4.37 0 0 0 1.5 8.04c0 1.126.358 2.098 1.073 2.917A4.533 4.533 0 0 0 5.25 12.48V14Zm1.5 0v-1.52a4.414 4.414 0 0 0 2.677-1.511c.715-.827 1.073-1.802 1.073-2.927 0-1.25-.438-2.313-1.313-3.188C8.313 3.98 7.25 3.542 6 3.542h-.146l.958.958L5.75 5.542 3 2.792 5.75.042l1.063 1.062-.938.938H6c1.667 0 3.083.583 4.25 1.75C11.417 4.958 12 6.375 12 8.042c0 1.541-.504 2.875-1.51 4C9.483 13.167 8.236 13.819 6.75 14Z"
                    />
                  </svg>
                  Reset
                </button>
              </div>

              <div className="mt-8 flow-root rounded-lg border border-gray-200 bg-white">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="relative">
                      {selectedPeople.length > 0 && (
                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                          <button
                            type="button"
                            className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                          >
                            Bulk edit
                          </button>
                          <button
                            type="button"
                            className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                          >
                            Delete all
                          </button>
                        </div>
                      )}
                      <table className="min-w-full table-fixed divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6"
                            >
                              {/* <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-[#FCAF17] focus:ring-[#FCAF17]"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                              /> */}
                            </th>
                            <th
                              scope="col"
                              className="border-r border-gray-200 py-3.5 pr-3 text-left text-sm font-normal text-gray-400"
                            >
                              <div className="flex items-center space-x-1">
                                <span>Supplier Code</span>
                                <button type="button">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                            >
                              <div className="flex items-center space-x-1">
                                <span>Document Type</span>
                                <button type="button">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                            >
                              <div className="flex items-center space-x-1">
                                <span>Document Number</span>
                                <button type="button">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                            >
                              <div className="flex items-center space-x-1">
                                <span>Machine Type</span>
                                <button type="button">
                                  <ChevronDownIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                            >
                              <span className="sr-only">Details</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {doc_data && doc_data.length > 0 ? (
                            doc_data.map((item, index) => (
                              <tr
                                key={index}
                                className={
                                  selectedPeople.includes(item)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="relative px-7 py-8 sm:w-12 sm:px-6">
                                  {selectedPeople.includes(item) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-[#FCAF17]" />
                                  )}
                                </td>
                                <td
                                  className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500"
                                  title={item.supplier_code}
                                >
                                  {item.supplier_code
                                    ? item.supplier_code
                                    : `N/A`}
                                </td>
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  title={item.document_type}
                                >
                                  {item.document_type
                                    ? item.document_type
                                    : `N/A`}
                                </td>
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  title={item.document_no}
                                >
                                  {item.document_no ? item.document_no : `N/A`}
                                </td>
                                <td
                                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                  title={item.machine_type}
                                >
                                  {item.machine_type
                                    ? item.machine_type
                                    : `N/A`}
                                </td>
                                <td className="mx-auto whitespace-nowrap text-center text-sm font-medium">
                                  <button
                                    type="button"
                                    onClick={() => openDocumentModal(item)}
                                    className="text-gray-600 hover:text-gray-900"
                                  >
                                    <EyeIcon className="h-5 w-5" />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {data_loading ? (
                                  <span className="fa fa-spinner fa-spin "></span>
                                ) : (
                                  <div>No Data Found</div>
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                  <div className="flex flex-1 justify-between sm:hidden">
                    {/* <a
                        href="#"
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                      >
                        Next
                      </a> */}
                  </div>
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing{" "}
                        <span className="font-medium">
                          {offset && offset > 0 ? offset : 0}
                        </span>{" "}
                        to{" "}
                        <span className="font-medium">
                          {upto && upto > 0 ? upto : 0}
                        </span>{" "}
                        of{" "}
                        <span className="font-medium">
                          {total && total > 0 ? total : 0}
                        </span>{" "}
                        results
                      </p>
                    </div>
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md"
                        aria-label="Pagination"
                      >
                        <PaginationCustom
                          onClickPrev={() =>
                            page > 1
                              ? (setPage(page - 1), handlePageChange("-"))
                              : null
                          }
                          onClickNext={() =>
                            page < no_of_pages
                              ? (setPage(page + 1), handlePageChange("+"))
                              : null
                          }
                          disabledPrev={page === 1 ? true : false}
                          disabledNext={page === no_of_pages ? true : false}
                        >
                          {renderPageNumbers()}
                        </PaginationCustom>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ViewDocument;

import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "26cfe3f3-7752-49e4-95fa-82811522efb6",
    authority:
      "https://login.microsoftonline.com/c6f6dd74-a042-4f10-8258-6b311f5d8ccc",
    redirectUri: window.location.origin,
  },
};
const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
  prompt: "select_account",
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, loginRequest, msalConfig };

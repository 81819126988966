export const datatableCustomStyles = {
    rows: {
        style: {
            minHeight: "35px",
        },
    },
    cells: {
        style: {
            borderTop: "0px solid #F4F6F9",
            borderRight: "0px solid #F4F6F9",
            borderBottom: "1px solid #CAC2BC",
            background: "#FFFFFF",
            fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
            fontWeight: "normal",
            fontSize: "12px",
            color: "#221B13",
            padding: "10px 10px 10px 10px",
            lineHeight: "24px",
        },
    },
    headCells: {
        style: {
            background: "#FFFFFF",
            border: "0px solid #f0f3f7 ",
            fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
            color: "#000000",
            paddingBottom: "15px",
            paddingLeft: "8px",
            paddingTop: "10px",
            lineHeight: "21px",
            fontWeight: "bold !important",
            fontStyle: "normal !important",
            fontSize: "12px !important",
        },
    },
};

export const datatableCustomStyles2 = {
    rows: {
        style: {
            minHeight: "35px",
        },
    },
    cells: {
        style: {
            borderTop: "0px solid #F4F6F9",
            borderRight: "0px solid #F4F6F9",
            background: "#FFFFFF",
            fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
            fontWeight: "normal",
            fontSize: "12px",
            color: "#221B13",
            padding: "10px 10px 10px 10px",
            lineHeight: "24px",
        },
    },
    headCells: {
        style: {
            background: "#D3D3D3",
            fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
            color: "#000000",
            paddingBottom: "15px",
            paddingLeft: "8px",
            paddingTop: "10px",
            lineHeight: "21px",
            fontWeight: "bold !important",
            fontStyle: "normal !important",
            fontSize: "12px !important",
        },
    },
};

import { timeSheetService } from "../../services/timesheet.service";
import { alertActions } from "./alert.action";
import { timesheetConstants } from "./types/timeSheet.constants";

export const timesheetActions = {
  submitTimesheetData,
  getTimesheetlist,
  getJsonDataFrombase64,
  SaveFileResults,
  getOptionsList,
  getSheetById,
  timesheetApprovedOrAudit,
  updateTimesheetData,
  getMessagesById,
  getDashBoardGraphData,
  UpdateTimesheetStatus,
};

function submitTimesheetData(timeSheet) {
  return (dispatch) => {
    dispatch(request(timeSheet));
    timeSheetService.submitTimeSheetData(timeSheet).then(
      (timesheet_res) => {
        if (timesheet_res.code === 200) {
          dispatch(alertActions.success(timesheet_res.message));
        } else {
          dispatch(alertActions.error(timesheet_res.message));
        }
        dispatch(success({ timesheet_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(timeSheet) {
    return { type: timesheetConstants.TIMESHEET_REQUEST, timeSheet };
  }
  function success(timesheet_res) {
    if (timesheet_res.timesheet_res.code === 200) {
      return {
        type: timesheetConstants.TIMESHEET_SUCCESS,
        timesheet_res,
      };
    } else {
      return {
        type: timesheetConstants.TIMESHEET_FAILURE,
        timesheet_res,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.TIMESHEET_FAILURE, error };
  }
}

function getTimesheetlist(
  sheetStatus,
  startDate,
  endDate,
  tms_search,
  company,
  pageNo,
  VehicleCode
) {
  return (dispatch) => {
    dispatch(request());
    timeSheetService
      .getTimesheetlist(
        sheetStatus,
        startDate,
        endDate,
        tms_search,
        company,
        pageNo,
        VehicleCode
      )
      .then(
        (timesheet_list) => dispatch(success(timesheet_list)),
        (error) => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return { type: timesheetConstants.GET_TIMESHEET_REQUEST };
  }
  function success(timesheet_list) {
    if (timesheet_list.code === 200) {
      return {
        type: timesheetConstants.GET_TIMESHEET_SUCCESS,
        timesheet_list,
      };
    } else {
      return {
        type: timesheetConstants.GET_TIMESHEET_FAILURE,
        timesheet_list,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.GET_TIMESHEET_FAILURE, error };
  }
}

function getJsonDataFrombase64(base64data) {
  return (dispatch) => {
    dispatch(request(base64data));
    timeSheetService.getJsonDataFrombase64(base64data).then(
      (base64_res) => {
        if (base64_res.code === 200) {
          dispatch(alertActions.success(base64_res.message));
        } else {
          dispatch(alertActions.error(base64_res.message));
        }
        dispatch(success({ base64_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(base64data) {
    return { type: timesheetConstants.GET_BASE64_REQUEST, base64data };
  }
  function success(base64_res) {
    if (base64_res.base64_res.code === 200) {
      return {
        type: timesheetConstants.GET_BASE64_SUCCESS,
        base64_res,
      };
    } else {
      return {
        type: timesheetConstants.GET_BASE64_FAILURE,
        base64_res,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.GET_BASE64_FAILURE, error };
  }
}

function SaveFileResults(file_data) {
  return (dispatch) => {
    dispatch(request(file_data));
    timeSheetService.SaveFileResults(file_data).then(
      (file_res) => {
        if (file_res.code === 200) {
          dispatch(alertActions.success(file_res.message));
        } else {
          dispatch(alertActions.error(file_res.message));
        }
        dispatch(success({ file_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(file_data) {
    return { type: timesheetConstants.FILEDATA_REQUEST, file_data };
  }
  function success(file_res) {
    if (file_res.file_res.code === 200) {
      return {
        type: timesheetConstants.FILEDATA_SUCCESS,
        file_res,
      };
    } else {
      return {
        type: timesheetConstants.FILEDATA_FAILURE,
        file_res,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.FILEDATA_FAILURE, error };
  }
}

function getOptionsList() {
  return (dispatch) => {
    dispatch(request());
    timeSheetService.getOptionsList().then(
      (options_list) => dispatch(success(options_list)),
      (error) => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: timesheetConstants.GET_OPTIONS_REQUEST };
  }
  function success(options_list) {
    if (options_list.code === 200) {
      return {
        type: timesheetConstants.GET_OPTIONS_SUCCESS,
        options_list,
      };
    } else {
      return {
        type: timesheetConstants.GET_OPTIONS_FAILURE,
        options_list,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.GET_VIEWER_FAILURE, error };
  }
}

function getSheetById(id) {
  return (dispatch) => {
    dispatch(request());
    timeSheetService.getSheetById(id).then(
      (sheet_data) => dispatch(success(sheet_data)),
      (error) => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: timesheetConstants.GET_SHEET_BY_ID_REQUEST };
  }
  function success(sheet_data) {
    if (sheet_data.code === 200) {
      return {
        type: timesheetConstants.GET_SHEET_BY_ID_SUCCESS,
        sheet_data,
      };
    } else {
      return {
        type: timesheetConstants.GET_SHEET_BY_ID_FAILURE,
        sheet_data,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.GET_SHEET_BY_ID_FAILURE, error };
  }
}

function timesheetApprovedOrAudit(data_dict) {
  return (dispatch) => {
    dispatch(request(data_dict));
    timeSheetService.timesheetApprovedOrAudit(data_dict).then(
      (sheet_res) => {
        if (sheet_res.code === 200) {
          dispatch(alertActions.success(sheet_res.message));
        } else {
          dispatch(alertActions.error(sheet_res.message));
        }
        dispatch(success({ sheet_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(sheet_res) {
    return {
      type: timesheetConstants.SHEET_APPROVE_AUDIT_REQUEST,
      sheet_res,
    };
  }
  function success(sheet_res) {
    if (sheet_res.sheet_res.code === 200) {
      return {
        type: timesheetConstants.SHEET_APPROVE_AUDIT_SUCCESS,
        sheet_res,
      };
    } else {
      return {
        type: timesheetConstants.SHEET_APPROVE_AUDIT_FAILURE,
        sheet_res,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.SHEET_APPROVE_AUDIT_FAILURE, error };
  }
}

function updateTimesheetData(updated_timeSheet, selectedsheetid) {
  return (dispatch) => {
    dispatch(request(updated_timeSheet, selectedsheetid));
    timeSheetService
      .updateTimesheetData(updated_timeSheet, selectedsheetid)
      .then(
        (update_timesheet_res) => {
          if (update_timesheet_res.code === 200) {
            dispatch(alertActions.success(update_timesheet_res.message));
          } else {
            dispatch(alertActions.error(update_timesheet_res.message));
          }
          dispatch(success({ update_timesheet_res }));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(timeSheet) {
    return { type: timesheetConstants.UPDATE_TIMESHEET_REQUEST, timeSheet };
  }
  function success(update_timesheet_res) {
    if (update_timesheet_res.update_timesheet_res.code === 200) {
      return {
        type: timesheetConstants.UPDATE_TIMESHEET_SUCCESS,
        update_timesheet_res,
      };
    } else {
      return {
        type: timesheetConstants.UPDATE_TIMESHEET_FAILURE,
        update_timesheet_res,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.UPDATE_TIMESHEET_FAILURE, error };
  }
}

function getMessagesById() {
  return (dispatch) => {
    dispatch(request());
    timeSheetService.getMessagesById().then(
      (msg_data) => dispatch(success(msg_data)),
      (error) => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: timesheetConstants.GET_MESSAGE_REQUEST };
  }
  function success(msg_data) {
    if (msg_data.code === 200) {
      return {
        type: timesheetConstants.GET_MESSAGE_SUCCESS,
        msg_data,
      };
    } else {
      return {
        type: timesheetConstants.GET_MESSAGE_FAILURE,
        msg_data,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.GET_MESSAGE_FAILURE, error };
  }
}

function getDashBoardGraphData(monthCount) {
  return (dispatch) => {
    dispatch(request());
    timeSheetService.getDashBoardGraphData(monthCount).then(
      (graph_data) => dispatch(success(graph_data)),
      (error) => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return { type: timesheetConstants.DASHBOARD_GRAPH_REQUEST };
  }
  function success(graph_data) {
    if (graph_data.code === 200) {
      return {
        type: timesheetConstants.DASHBOARD_GRAPH_SUCCESS,
        graph_data,
      };
    } else {
      return {
        type: timesheetConstants.DASHBOARD_GRAPH_FAILURE,
        graph_data,
      };
    }
  }
  function failure(error) {
    return { type: timesheetConstants.DASHBOARD_GRAPH_FAILURE, error };
  }
}

function UpdateTimesheetStatus(timesheet_data) {
  return (dispatch) => {
    dispatch(request(timesheet_data));
    timeSheetService.updateTimesheetStatus(timesheet_data).then(
      (tmsht_res) => {
        if (tmsht_res.code === 200) {
          dispatch(alertActions.success(tmsht_res.message));
        } else {
          dispatch(alertActions.error(tmsht_res.message));
        }
        dispatch(success({ tmsht_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(a_dict) {
    return {
      type: timesheetConstants.UPDATE_TIMESHEET_STATUS_REQUEST,
      a_dict,
    };
  }
  function success(tmsht_res) {
    if (tmsht_res.tmsht_res.code === 200) {
      return {
        type: timesheetConstants.UPDATE_TIMESHEET_STATUS_SUCCESS,
        tmsht_res,
      };
    } else {
      return {
        type: timesheetConstants.UPDATE_TIMESHEET_STATUS_FAILURE,
        tmsht_res,
      };
    }
  }
  function failure(error) {
    return {
      type: timesheetConstants.UPDATE_TIMESHEET_STATUS_FAILURE,
      error,
    };
  }
}

import * as config from "../config/config";
import { authHeader } from "./http.header";

export const timeSheetService = {
  submitTimeSheetData,
  getTimesheetlist,
  getJsonDataFrombase64,
  SaveFileResults,
  getOptionsList,
  getSheetById,
  timesheetApprovedOrAudit,
  updateTimesheetData,
  getMessagesById,
  getDashBoardGraphData,
  updateTimesheetStatus,
};

function submitTimeSheetData(timesheetData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(timesheetData),
  };

  return fetch(`${config.apiUrl}/timesheet/`, requestOptions)
    .then(handleResponse)
    .then((timesheet_res) => {
      return timesheet_res;
    });
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === 200) {
    } else {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
function getTimesheetlist(
  sheetStatus,
  startDate,
  endDate,
  tms_search,
  company,
  pageNo,
  VehicleCode

) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/timesheet/?sheetStatus=${sheetStatus}&startDate=${startDate}&endDate=${endDate}&tms_search=${tms_search}&company=${company}&page_no=${pageNo}&VehicleCode=${VehicleCode}`,
    requestOptions
  ).then(handleResponse);
}

function getJsonDataFrombase64(base64data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(base64data),
  };

  return fetch(`${config.apiUrl}/convert-base64-to-json/`, requestOptions)
    .then(handleResponse)
    .then((base64_res) => {
      return base64_res;
    });
}

function SaveFileResults(file_data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(file_data),
  };

  return fetch(`${config.apiUrl}/save-file-result/`, requestOptions)
    .then(handleResponse)
    .then((file_res) => {
      return file_res;
    });
}

function getOptionsList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/options-list/`, requestOptions).then(
    handleResponse
  );
}

function getSheetById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/timesheet-by-id/?sheet_id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function timesheetApprovedOrAudit(sheet_data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(sheet_data),
  };

  return fetch(`${config.apiUrl}/approve-audit-timesheet/`, requestOptions)
    .then(handleResponse)
    .then((sheet_res) => {
      return sheet_res;
    });
}

function updateTimesheetData(updated_timeSheet, selectedsheetid) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(updated_timeSheet),
  };

  return fetch(
    `${config.apiUrl}/timesheet-by-id/?sheet_id=${selectedsheetid}`,
    requestOptions
  )
    .then(handleResponse)
    .then((update_timesheet_res) => {
      return update_timesheet_res;
    });
}

function getMessagesById() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/get-message-by-id/`, requestOptions).then(
    handleResponse
  );
}

function getDashBoardGraphData(monthCount) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/dashboard-graph-data/?monthCount=${monthCount}`,
    requestOptions
  ).then(handleResponse);
}

function updateTimesheetStatus(tmsht_data) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(tmsht_data),
  };

  return fetch(`${config.apiUrl}/timesheet/`, requestOptions)
    .then(handleResponse)
    .then((tmsht_res) => {
      return tmsht_res;
    });
}

import { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { machineActions } from "../store/actions/machine.action";
import MachinepopUp from "./MachinePopUp";
import PaginationCustom from "./PaginationCustom";
import PaginationPageNum from "./PaginationPageNum";
import harvester from "../assets/icons/Harvester.png";
import bulldozer from "../assets/icons/Bulldozer.png";
import BackhoeLoader from "../assets/icons/BackhoeLoader.png";
import Telehandler from "../assets/icons/Telehandler.png";
// import Excavator from "../assets/icons/Excavator.png";
import SteerLoader from "../assets/icons/SteerLoader.png";
import SingleManLift from "../assets/icons/SingleManLift.png";
import Forklift from "../assets/icons/Forklift.png";
import Scissor from "../assets/icons/Scissor.png";
import SimpleMachine from "../assets/icons/SimpleMachine.png";
import ArticulatedDumpTruck from "../assets/icons/ArticulatedDumpTruck.png";
import SelectOptionDropDown from "./SelectOptionDropDown";
import image from "../assets/icons/machines-img.svg";
import { handleLongString } from "../helpers/Helper";
import MachinePopUp from "./secondMachinePopup";
// import { Sidebar } from "../components/sidebar";
import SideBar from "./SideBar";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import Excavator from "../assets/images/excavator.png";
import Dozer from '../assets/images/Dozer.png';
import Grader from '../assets/images/Grader.png';
import Truck from "../assets/images/truck.png";
import Front_End_Loader from "../assets/images/Front End Loader.png";
import Tractor_Loader_Backhoe from "../assets/images/Tractor Loader Backhoe.png";
import Tipper_Truck from "../assets/images/Tipper Truck.png";
import Water_Tanker from "../assets/images/Water Tanker.png";
import Articulated_Dump_Truck from '../assets/images/Articulated Dump Truck.png';

import { Modal } from "../components/modals/machine";
import { MachineSubType } from "../components/selectors/machineSubtype";
import { MachineType } from "../components/selectors/machineType";
import { MachineContractor } from "./selectors/contractor";
function Machines() {
  const [reset, setReset] = useState(false);
  const updatePageNo = (newState) => {
    setPage(newState);
  };
  const user_dt = useSelector((state) => state.users);
  const companyList = [
    {
      label: "Red Top Asset Management",
      value: "Red Top Asset Management",
      type: "machComapny",
    },
    {
      label: "Quartz Plant Hire",
      value: "Quartz Plant Hire",
      type: "machComapny",
    },
    {
      label: "KLT Machinery and Plant Hire",
      value: "KLT Machinery and Plant Hire",
      type: "machComapny",
    },
  ];
  const user_role =
    user_dt.items !== undefined && user_dt.items["role_detail"]["role"];
  const [selectedType, setSelectedType] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("");
  const [searchItem, setSearchitem] = useState("");
  const [searchValue, setSearchValue] = useState(null);

  const HandleSearchChange2 = (search) => {
    localStorage.setItem("search", search);
    setSearchitem(search);
    setSearchValue(search);
  };

  const HandleSearchChange = (search) => {
    localStorage.setItem("search", search);
    setSearchitem(search);
    setPage(1);
    setSearchValue(search);

    dispatch(
      machineActions.getMachinelist(
        localStorage.getItem("machineType")
          ? localStorage.getItem("machineType")
          : null,
        localStorage.getItem("machineSubtype")
          ? localStorage.getItem("machineSubtype")
          : null,

        search,
        localStorage.getItem("machComapny")
          ? localStorage.getItem("machComapny")
          : null,
        1
      )
    );
  };

  const HandleClearFilters = () => {
    setReset(!reset);
    HandleSearchChange2("");
    dispatch(machineActions.getMachinelist(null, null, null, null, page));
    localStorage.removeItem("search");
    localStorage.removeItem("machineType");
    localStorage.removeItem("machineSubtype");
    localStorage.removeItem("search");
    localStorage.removeItem("machComapny");
  };

  const handlePageChange = (pageNo) => {
    console.log("page....", pageNo)
    if (pageNo === "-") {
      if (page > 1) {
        dispatch(
          machineActions.getMachinelist(
            localStorage.getItem("machineType")
              ? localStorage.getItem("machineType")
              : null,
            localStorage.getItem("machineSubtype")
              ? localStorage.getItem("machineSubtype")
              : null,
            localStorage.getItem("search")
              ? localStorage.getItem("search")
              : null,
            localStorage.getItem("machComapny")
              ? localStorage.getItem("machComapny")
              : null,
            page - 1
          )
        );
      }
    }

    if (pageNo === "+") {
      if (page < no_of_pages) {
        dispatch(
          machineActions.getMachinelist(
            localStorage.getItem("machineType")
              ? localStorage.getItem("machineType")
              : null,
            localStorage.getItem("machineSubtype")
              ? localStorage.getItem("machineSubtype")
              : null,
            localStorage.getItem("search")
              ? localStorage.getItem("search")
              : null,
            localStorage.getItem("machComapny")
              ? localStorage.getItem("machComapny")
              : null,
            page + 1
          )
        );
      }
    }
  };

  const [page, setPage] = useState(1);

  const handlePageChange2 = (pageNo) => {
    setPage(pageNo);
    console.log("page....here 2", pageNo)

    dispatch(
      machineActions.getMachinelist(
        localStorage.getItem("machineType")
          ? localStorage.getItem("machineType")
          : null,
        localStorage.getItem("machineSubtype")
          ? localStorage.getItem("machineSubtype")
          : null,
        localStorage.getItem("search") ? localStorage.getItem("search") : null,
        localStorage.getItem("machComapny")
          ? localStorage.getItem("machComapny")
          : null,
        pageNo
      )
    );
  };

  const renderPageNumbers = () => {
    const elements = [];
    let new_no_of_pages = 1;
    let page_start = 1;
    if (no_of_pages > 4) {
      page_start = page;
      new_no_of_pages += 2 + page;
      if (new_no_of_pages >= no_of_pages) {
        new_no_of_pages = no_of_pages;
      }
      let page_diff = no_of_pages - page_start;

      if (page_diff <= 3) {
        page_start = no_of_pages - 4;
      }
    } else {
      new_no_of_pages = no_of_pages;
    }

    for (let i = page_start; i <= new_no_of_pages; i++) {
      elements.push(
        <PaginationPageNum
          key={i}
          active={page === i}
          onClick={() => handlePageChange2(i)}
        >
          {i}
        </PaginationPageNum>
      );
    }
    return elements;
  };
  const dispatch = useDispatch();
  const machine_type = useSelector((state) => state.MachineList.machine_type);

  const machine_list = useSelector((state) => state.MachineList.machine_data);
  // console.log("machine_list......", machine_list)

  const no_of_pages = useSelector((state) => state.MachineList.no_of_pages);
  const total = useSelector((state) => state.MachineList.total);
  const offset = useSelector((state) => state.MachineList.offset);
  const upto = useSelector((state) => state.MachineList.upto);

  const machine_loading = useSelector(
    (state) => state.MachineList.machine_loading
  );
  const machine_sub_type = useSelector(
    (state) => state.MachineList.machine_sub_type
  );
  // console.log("machine_sub_type......", machine_sub_type)

  const [machineData, setMachineData] = useState(null);
  const [machinePopUp, setMachinePopUp] = useState(false);

  const [open, setOpen] = useState(false);
  const openMachineModal = (machine_data) => {
    setOpen(true);
    setMachineData(machine_data);
  };

  useEffect(() => {
    localStorage.removeItem("machineType");
    localStorage.removeItem("machineSubtype");
    localStorage.removeItem("search");
    localStorage.removeItem("machComapny");
    dispatch(machineActions.getMachinelist(null, null, null, null, page));
    setReset(false);
  }, [reset]);

  return (
    <div className="flex">
      <Modal open={open} setOpen={setOpen} machine_data={machineData} />
      <SideBar />
      <main className="w-full min-w-[70rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="w-96 text-3xl font-semibold leading-6 text-gray-900">
                  View Machines
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  Here's an overview of your machines.
                </p>
              </div>
              <div className="mt-4 lg:ml-16 lg:mt-0 lg:w-3/4 ">
                <div>
                  <div className="w-full max-w-xs lg:ml-auto">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        onChange={(event) =>
                          HandleSearchChange(event.target.value)
                        }
                        value={searchValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 flex items-center space-x-5">
              {user_role &&
                (user_role === "Approver" || user_role === "CFO") ? (
                <MachineContractor
                  contractorList={companyList && companyList}
                  updatePageNo={updatePageNo}
                />
              ) : null}
              <MachineType
                machineTypes={machine_type && machine_type}
                updatePageNo={updatePageNo}
              />
              <MachineSubType
                machineTypes={
                  localStorage.getItem("machineType")
                    ? machine_sub_type && machine_sub_type
                    : null
                }
                updatePageNo={updatePageNo}
              />
              <button
                type="button"
                className="mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-[#FCAF17] px-5 py-[0.45rem] text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FCAF17]"
                onClick={HandleClearFilters}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={14}
                  fill="none"
                >
                  <path
                    fill="#fff"
                    d="M5.25 14c-1.486-.194-2.733-.85-3.74-1.969C.503 10.913 0 9.583 0 8.041c0-.847.16-1.635.48-2.364.319-.73.756-1.365 1.312-1.906l1.062 1.062a4.464 4.464 0 0 0-.99 1.438A4.37 4.37 0 0 0 1.5 8.04c0 1.126.358 2.098 1.073 2.917A4.533 4.533 0 0 0 5.25 12.48V14Zm1.5 0v-1.52a4.414 4.414 0 0 0 2.677-1.511c.715-.827 1.073-1.802 1.073-2.927 0-1.25-.438-2.313-1.313-3.188C8.313 3.98 7.25 3.542 6 3.542h-.146l.958.958L5.75 5.542 3 2.792 5.75.042l1.063 1.062-.938.938H6c1.667 0 3.083.583 4.25 1.75C11.417 4.958 12 6.375 12 8.042c0 1.541-.504 2.875-1.51 4C9.483 13.167 8.236 13.819 6.75 14Z"
                  />
                </svg>
                Reset
              </button>
            </div>
            <ul role="list" className="my-8 grid w-full grid-cols-4 gap-8">
              {machine_list && machine_list.length > 0 ? (
                machine_list.map((data, index) => (
                  <li className="rounded-lg bg-white p-6" key={index}>
                    <div
                      className="crsr-pointer relative mx-auto w-full max-w-[15rem]"
                      onClick={() => openMachineModal(data)}
                    >
                      {/* <button
                        type="button"
                        className="absolute inset-0"
                      ></button> */}
                      <img
                        src={
                          (() => {

                            switch ((data?.machine_type || '').replace(/\s/g, '')) {
                              case "HydraulicExcavator":
                                return Excavator;
                              case "Excavator":
                                return Excavator;
                              case "LongReachExcavator":
                                return Excavator;
                              case "WaterTanker":
                                return Water_Tanker;
                              case "ArticulatedDumpTruck":
                                return Articulated_Dump_Truck;
                              case "TLB":
                                return Tractor_Loader_Backhoe;
                              case "TractorLoaderBackhoe":
                                return Tractor_Loader_Backhoe;

                              case "Dozer":
                                return Dozer;
                              case "Grader":
                                return Grader;
                              case "FrontEndLoader":
                                return Front_End_Loader;
                              case "TipperTruckADT":
                                return Tipper_Truck;

                              default:
                                return Truck;
                            }
                          })()
                        }
                        alt=""
                        className="mx-auto h-36 w-48"
                      />
                      <h3
                        className="mt-6 text-center text-lg text-gray-600"
                        title={data.machine_type}
                      >
                        {data.machine_type}
                      </h3>
                      <ul
                        role="list"
                        className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                      >
                        <li>
                          {data.machine_sub_type
                            ? data.machine_sub_type
                            : "N/A"}
                        </li>
                        <li>{data.make ? data.make : "N/A"}</li>
                        <li>{data.model ? data.model : "N/A"}</li>
                        <li>{data.vehicle_code ? data.vehicle_code : "N/A"}</li>
                      </ul>
                    </div>
                  </li>
                ))
              ) : machine_loading ? (
                <>
                  <div></div>
                  <div></div>
                  <div className="relative mx-auto w-full max-w-[15rem]">
                    <span className="fa fa-spinner fa-spin "></span>
                  </div>
                </>
              ) : (
                <>
                  <div></div>
                  <div></div>
                  No Data Found
                </>
              )}
            </ul>
            {/* <ul role="list" className="my-8 grid w-full grid-cols-4 gap-8">
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Truck} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Articulated Dump Truck
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Excavator} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Hydraulic excavator
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Truck} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Articulated Dump Truck
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Excavator} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Hydraulic excavator
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Truck} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Articulated Dump Truck
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Excavator} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Hydraulic excavator
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Truck} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Articulated Dump Truck
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
              <li className="rounded-lg bg-white p-6">
                <div className="relative mx-auto w-full max-w-[15rem]">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="absolute inset-0"
                  ></button>
                  <img src={Excavator} alt="" className="mx-auto h-36 w-48" />
                  <h3 className="mt-6 text-center text-lg text-gray-600">
                    Hydraulic excavator
                  </h3>
                  <ul
                    role="list"
                    className="mx-auto mt-5 grid w-full max-w-xs list-disc grid-cols-2 gap-y-2 pl-6 text-gray-500"
                  >
                    <li>6x24</li>
                    <li>Bell</li>
                    <li>B20B</li>
                    <li>B7</li>
                  </ul>
                </div>
              </li>
            </ul> */}
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {offset && offset > 0 ? offset : 0}
                    {/* {page === 1
                      ? 1
                      : machine_list && machine_list.length > 0
                      ? page !== no_of_pages
                        ? machine_list.length * (page - 1) + 1
                        : no_of_pages * 9 - machine_list.length
                      : 0} */}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {/* {machine_list && machine_list.length > 0
                      ? page !== no_of_pages
                        ? machine_list.length * page
                        : no_of_pages * 9
                      : 0} */}
                    {upto && upto > 0 ? upto : 0}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {total && total > 0 ? total : 0}
                  </span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md"
                  aria-label="Pagination"
                >
                  {/* <a
                    href="#"
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                  <a
                    href="#"
                    aria-current="page"
                    className="relative z-10 hidden items-center rounded-full bg-[#FCAF17] px-4 py-2 text-sm font-semibold text-white lg:inline-flex"
                  >
                    1
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    3
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    4
                  </a>
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </a> */}
                  <PaginationCustom
                    onClickPrev={() =>
                      page > 1
                        ? (setPage(page - 1), handlePageChange("-"))
                        : null
                    }
                    onClickNext={() =>
                      page < no_of_pages
                        ? (setPage(page + 1), handlePageChange("+"))
                        : null
                    }
                    disabledPrev={page === 1 ? true : false}
                    disabledNext={page === no_of_pages ? true : false}
                  >
                    {renderPageNumbers()}
                  </PaginationCustom>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Machines;

// //export const apiUrl = "http://54.235.66.17";

// export const apiUrl = "http://127.0.0.1:8000";

// //export const apiUrl = process.env.API_ENDPOINT;

export const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : "https://api.yello-ai.com";
// : "http://54.235.66.17";

export const calenderDays = 30;

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Dispatch, Fragment, SetStateAction } from "react";
import moment from "moment";

export function Modal({ open, setOpen, anomalie_data }) {
  const anomalieData = anomalie_data?anomalie_data:null
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <form>
                  <div className="space-y-6">
                    <div>
                      <div className="flex items-center justify-between pb-4">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900">
                          Anomaly Details
                        </h2>
                        <button type="button" onClick={() => setOpen(false)}>
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>

                      <hr className="-mx-6" />

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-type"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice-PO Price Difference
                          </label>
                          <div className="mt-2">
                             <input
                              disabled
                              type="text"
                              name="machine-type"
                              id="machine-type"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Articulated Dump Truck"
                              value={anomalieData && anomalieData.INV_PO_Price_Difference? `(R) ${anomalieData.INV_PO_Price_Difference}`:'N/A'}
                            />
                          </div>
                        </div>

                        

                        

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="make"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Reason for Price Anomaly
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="make"
                              id="make"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Bell"
                              value={anomalieData && anomalieData.INV_PO_Price_Anomaly_Reason?anomalieData.INV_PO_Price_Anomaly_Reason:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-age"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Wet Day Rate
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-age"
                              id="machine-age"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="3"
                              value={anomalieData && anomalieData.WetDayRate? `(R) ${anomalieData.WetDayRate}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-model"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Dry Day Rate
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-model"
                              id="machine-model"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="B20B"
                              value={anomalieData && anomalieData.DryDayRate? `(R) ${anomalieData.DryDayRate}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-model"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            PO-Wet Rate Difference
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-model"
                              id="machine-model"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="B20B"
                              value={anomalieData && anomalieData.PO_Wet_Rate_Difference? `(R) ${anomalieData.PO_Wet_Rate_Difference}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="imei-number"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            PO-Dry Rate Difference
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="imei-number"
                              id="imei-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="1236547896325"
                              value={anomalieData && anomalieData.PO_Dry_Rate_Difference? `(R) ${anomalieData.PO_Dry_Rate_Difference}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="vehicle-code"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Number
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="vehicle-code"
                              id="vehicle-code"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="B7"
                              value={anomalieData && anomalieData.INV_Invoice?anomalieData.INV_Invoice:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="contractor-company"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Date
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="contractor-company"
                              id="contractor-company"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Red Top Asset Management"
                              value={anomalieData && anomalieData.INV_InvoiceDate?moment(
                                anomalieData.INV_InvoiceDate
                            ).format("YYYY-MM-DD"):'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="contractor-company"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Due Date
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="contractor-company"
                              id="contractor-company"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Red Top Asset Management"
                              value={anomalieData && anomalieData.INV_DueDate?moment(
                                anomalieData.INV_DueDate
                            ).format("YYYY-MM-DD"):'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Original Invoice Value
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.INV_OrigInvValue? `(R) ${anomalieData.INV_OrigInvValue}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Transaction Value
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.INVPay_TrnValue? `(R) ${anomalieData.INVPay_TrnValue}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Transaction Type
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.INVPay_TrnType?anomalie_data.INVPay_TrnType === "P" &&
                              "Payment"?anomalie_data.INVPay_TrnType === "A" &&
                              "Adjustment":anomalie_data.INVPay_TrnType === "C" &&
                              "Credit Note":'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Linked Document Number
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.INV_Reference?anomalieData.INV_Reference:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Number
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.PODet_PurchaseOrder?anomalieData.PODet_PurchaseOrder:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Entry Date
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.POHdr_OrderEntryDate?moment(
                                anomalieData.POHdr_OrderEntryDate
                            ).format("YYYY-MM-DD"):'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Due Date
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.POHdr_OrderDueDate?moment(
                                anomalieData.POHdr_OrderDueDate
                            ).format("YYYY-MM-DD"):'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Stock Code
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.PODet_MStockCode?anomalieData.PODet_MStockCode:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Price
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.PODet_MPrice? `(R) ${anomalieData.PODet_MPrice}`:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Type
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.MachineType?anomalieData.MachineType:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Subtype
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.MachineSubType?anomalieData.MachineSubType:'N/A'}
                            />
                          </div>
                        </div>

                        

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-subtype"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Purchase Order Price Anomaly Indicator
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-subtype"
                              id="machine-subtype"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="6*4"
                              value={anomalieData && anomalieData.INV_PO_Price_Anomaly_Indicator?anomalieData.INV_PO_Price_Anomaly_Indicator ===
                                false ||
                                anomalieData.INV_PO_Price_Anomaly_Indicator ===
                                true
                                ? anomalieData.INV_PO_Price_Anomaly_Indicator.toString()
                                : `N/A`:`N/A`}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-year"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Purchase Order Price Anomaly Status
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-year"
                              id="machine-year"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="2020"
                              value={anomalieData && anomalieData.INV_PO_Price_Anomaly_Status?anomalieData.INV_PO_Price_Anomaly_Status:'N/A'}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="machine-description"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Hire Type
                          </label>
                          <div className="mt-2">
                             <input
                            disabled

                              type="text"
                              name="machine-description"
                              id="machine-description"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Test1234"
                              value={anomalieData && anomalieData.HireType?anomalieData.HireType:'N/A'}
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* <button
                    type="button"
                    className="focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex w-full justify-center rounded-md bg-[#FCAF17] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    Save
                  </button> */}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "./NavBar";
import { MDBContainer } from "mdbreact";
import { MDBBtn } from "mdb-react-ui-kit";
import { machineActions } from "../store/actions/machine.action";
import axios from "axios";
import * as config from "../config/config";
import getOptions from "../services/http.header";
// import { Sidebar } from "../components/sidebar";
import SideBar from "./SideBar";
import Select from "react-select";

const AddMachine = () => {
  const imei_list = useSelector((state) => state.getMachineImeiNo.imei_list);
  const imei_list_loading = useSelector(
    (state) => state.getMachineImeiNo.data_loading
  );
  const vechicles_data_loading = useSelector(
    (state) => state.getVehicleData.data_loading
  );
  const dataById = useSelector((state) => state.getMachineDataById.data);
  const selectedMachineid = window.location.href.split("id=")[1];
  // const [selectedMachineid, setSelectedMachineid] = useState(
  //     window.location.href.split("id=")[1]
  //         ? window.location.href.split("id=")[1]
  //         : null
  // );

  const [showMsg, setShowMsg] = useState(false);
  const user_dt = useSelector((state) => state.users);

  const type_list = useSelector((state) => state.getMachineType.type_list);
  const imei_list2 = useSelector((state) => state.getMachineType.imei_list);
  const v_codeListList = useSelector(
    (state) => state.getMachineType.v_codeListList
  );

  const datalist = useSelector((state) => state.getMachineType.data_list);

  const type_list_loading = useSelector(
    (state) => state.getMachineType.data_loading
  );
  const [typeListOpion, setTypeListOpion] = useState([]);

  const [imeiListOpion, setImeiListOpion] = useState([]);

  const addMachineMsg = useSelector((state) => state.AddMachine.msg);
  const addMachineLoading = useSelector(
    (state) => state.AddMachine.machine_loading
  );

  const addMachineCode = useSelector((state) => state.AddMachine.code);
  const vehiclesData = useSelector((state) => state.getVehicleData.data);

  const dispatch = useDispatch();
  const company = user_dt.items !== undefined && user_dt.items["company_name"];
  const [discription, setDiscription] = useState(null);
  const [machineMake, setMachinheMake] = useState(null);

  const [machineAge, setMachinheAge] = useState(null);
  const [machineModel, setMachinheModel] = useState(null);
  const [machineYear, setMachinheYear] = useState(null);
  const [machineType, setMachinheType] = useState(null);
  const [machineType2, setMachinheType2] = useState(null);
  const [vehicleCode, setVehicleCode] = useState(null);
  const [machineSubType, setMachinheSubType] = useState(null);
  const [imeiNo, setImeiNo] = useState(null);
  const [validationMessages, setValidationMessages] = useState([]);

  const [ImeiNames, setImeiNames] = useState([]);
  const [ImeiNames2, setImeiNames2] = useState(null);
  const [typeNames, setTypeNames] = useState([]);
  const [vehicleCodes, setVehicleCodes] = useState([]);
  const validYearRegex = /^(19|20)\d{2}$/;

  const handleYearChange = (entered_year) => {
    if (entered_year > 2023) {
      setMachinheYear(2023);
    } else {
      setMachinheYear(entered_year);
    }
  };

  const handleMachineAgeChange = (age) => {
    let mach_age = 0;
    if (age) {
      mach_age = parseFloat(age);
      if (mach_age > 100) {
        mach_age = 100;
      }
      if (mach_age < 0) {
        mach_age = 0;
      }
      setMachinheAge(mach_age);
    } else {
      setMachinheAge(age);
    }
  };

  const handleTypeChange = (event) => {
    setImeiNames([]);
    setShowMsg(false);
    setMachinheType(event);
    setImeiNo("Select");
    // dispatch(machineActions.getMachineImeiNo(event));
    setMachinheMake(null);
    setMachinheYear(null);
    setMachinheModel(null);
    setMachinheAge(null);
    setVehicleCode(null);
    setDiscription(null);
    setMachinheSubType(null);

    if (event !== "Select") {
      setImeiNames((datalist) =>
        datalist.filter((val) => val["type"] === event)
      );
      let data = datalist;

      data = data
        .filter(function (item) {
          return item.type === event;
        })
        .map(function ({ imei }) {
          return { key: imei, value: imei };
        });
      data.splice(0, 0, { key: "Select", value: "Select" });
      setImeiNames(data);
    }
  };

  const handleVehicleCodeChange = (event) => {
    setShowMsg(false);
    let data = datalist;
    data = data
      .filter(function (item) {
        return item.v_code === event;
      })
      .map(function ({ subtype, model, make, year, age, type, imei }) {
        return {
          subtype: subtype,
          model: model,
          make: make,
          year: year,
          age: age,
          discription: null,
          v_code: event,
          type: type,
          imei: imei,
        };
      });
    setMachinheMake(data[0]["make"]);
    setMachinheYear(data[0]["year"]);
    setMachinheModel(data[0]["model"]);
    setMachinheAge(data[0]["age"]);
    setVehicleCode(data[0]["v_code"]);
    setDiscription(null);
    setMachinheSubType(data[0]["subtype"]);
    setMachinheType2(data[0]["type"]);
    if (selectedMachineid) {
      setImeiNames2(data[0]["imei"]);
    }

    // if (event !== "Select") {
    //     setImeiNames((datalist) =>
    //         datalist.filter((val) => val["type"] === event)
    //     );
    //     let data = datalist;

    //     data = data
    //         .filter(function (item) {
    //             return item.type === event;
    //         })
    //         .map(function ({ imei }) {
    //             return { key: imei, value: imei };
    //         });
    //     data.splice(0, 0, { key: "Select", value: "Select" });
    //     setImeiNames(data);
    // }
  };

  const handleImeiNoChange = (event) => {
    setImeiNo(event);
    setShowMsg(false);
    let data = datalist;
    data = data
      .filter(function (item) {
        return item.imei === event && item.type === machineType;
      })
      .map(function ({ subtype, model, make, year, age, v_code }) {
        return {
          subtype: subtype,
          model: model,
          make: make,
          year: year,
          age: age,
          discription: null,
          v_code: v_code,
        };
      });
    setMachinheMake(data[0]["make"]);
    setMachinheYear(data[0]["year"]);
    setMachinheModel(data[0]["model"]);
    setMachinheAge(data[0]["age"]);
    setVehicleCode(data[0]["v_code"]);
    setDiscription(null);
    setMachinheSubType(data[0]["subtype"]);
  };

  const [isDataById, setIsDataById] = useState(false);

  if (isDataById === false) {
    if (dataById) {
      setIsDataById(true);
    }
    if (dataById) {
      // setImeiNames([
      //     { key: dataById["imei_no"], value: dataById["imei_no"] },
      // ]);
      // setTypeNames([
      //     {
      //         key: dataById["machine_type"],
      //         value: dataById["machine_type"],
      //     },
      // ]);
      setVehicleCodes([
        {
          key: dataById["vehicle_code"],
          value: dataById["vehicle_code"],
        },
      ]);
      setImeiNames2(dataById["imei_no"]);
      setMachinheType2(dataById["machine_type"]);
      setMachinheMake(dataById["make"]);
      setMachinheAge(dataById["machine_age"]);
      setImeiNo(dataById["imei_no"]);
      setMachinheModel(dataById["model"]);
      setVehicleCode(dataById["vehicle_code"]);
      setDiscription(dataById["discription"]);
      setMachinheYear(dataById["year"]);
      setMachinheSubType(dataById["machine_sub_type"]);
      // setMachinheType2(data[0]["type"]);
      // setImeiNames2(data[0]["imei"]);
    }
  }

  const submit = () => {
    let dataDict = {
      vehicle_code: vehicleCode,
      global_vehicle_code: vehicleCode,
      plant: "",
      name: machineType,
      model: machineModel,
      make: machineMake,
      year: machineYear,
      contractor_company: company,
      machine_age: machineAge,
      discription: discription,
      imei_no: ImeiNames2,
      machine_sub_type: machineSubType,
      machine_type: machineType2,
    };

    let messages = [];

    if (!machineType2) {
      messages.push("Type is required");
    }
    if (!ImeiNames2) {
      messages.push("IMEI No is required");
    }

    if (!machineAge) {
      messages.push(" Age is required");
    }
    if (!machineMake) {
      messages.push(" Make is required");
    }
    if (!machineModel) {
      messages.push(" Model is required");
    }
    if (machineYear) {
      if (!validYearRegex.test(machineYear)) {
        messages.push("Please enter a valid year.");
      }
    }

    if (!machineYear) {
      messages.push(" Year is required");
    }
    if (!machineSubType) {
      messages.push(" Sub Type is required");
    }
    if (!vehicleCode) {
      messages.push(" Vehicle Code is required");
    }

    if (vehicleCode === "Select") {
      messages.push(" Vehicle Code is required");
    }

    if (messages.length > 0) {
      setShowMsg(true);
      const error_violation = document.getElementById("msg_div");
      window.scrollTo({
        top: error_violation.offsetTop,
        behavior: "smooth",
      });
      setValidationMessages(messages);
    } else {
      messages = [];
      dispatch(machineActions.addMachine(dataDict));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("user_role") !== "Contractor") {
      window.location.assign("/");
    }
    // dispatch(machineActions.getMachineTypesAndSubtypes());
    if (selectedMachineid) {
      dispatch(machineActions.getMachineDataById(selectedMachineid));
    } else {
      // window.location.assign("/add-machine");
      dispatch(machineActions.getMachineType());
    }
  }, [selectedMachineid]);

  return (
    <div className="flex">
      <SideBar />
      <main className="w-full min-w-[70rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="text-3xl font-semibold leading-6 text-gray-900">
                  Add Machines
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  You can add new machines here.
                </p>
              </div>
            </div>
            <form className="mt-8 rounded-lg bg-white p-6">
              <div className="space-y-6">
                <div>
                  <div className="pb-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">
                      Add Machine
                    </h2>
                  </div>

                  <hr className="-mx-6" />

                  <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-4">
                    <div>
                      <label
                        htmlFor="vehicle-code"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Vehicle Code
                      </label>
                      <div className="mt-2">
                        <Select
                          disabled={v_codeListList ? false : true}
                          onChange={(event) =>
                            handleVehicleCodeChange(event.value)
                          }
                          options={v_codeListList ? v_codeListList : []}
                          defaultValue={
                            vehicleCode
                              ? { value: vehicleCode, label: vehicleCode }
                              : { value: "Select", label: "Select" }
                          }
                          name="vehicleCode"
                          id="vehicleCode"
                          className="select2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        />
                        {/* <select
                          name="vehicle-code"
                          id="vehicle-code"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          onChange={(event) =>
                            handleVehicleCodeChange(event.target.value)
                          }
                        >
                          {selectedMachineid
                            ? vehicleCodes.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))
                            : v_codeListList &&
                              v_codeListList.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))}
                        </select> */}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="imei-no"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        IMEI No.
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="imei-no"
                          id="imei-no"
                          min={0}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="1236457899"
                          defaultValue={ImeiNames2}
                          onChange={(event) => (
                            setImeiNames2(event.target.value), setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    {/* <div>
                      <label
                        htmlFor="company"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Company
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="company"
                          id="company"
                          disabled
                          value={company ? company : null}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder={"Red Top Asset Management"}
                        />
                      </div>
                    </div> */}

                    <div>
                      <label
                        htmlFor="age"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Age
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="age"
                          id="age"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          min={0}
                          max={100}
                          placeholder="0"
                          value={machineAge}
                          onChange={(event) => (
                            handleMachineAgeChange(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="model"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Model
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="model"
                          id="model"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="B20B"
                          defaultValue={machineModel}
                          onChange={(event) => (
                            setMachinheModel(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="make"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Make
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="make"
                          id="make"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Bell"
                          defaultValue={machineMake}
                          onChange={(event) => (
                            setMachinheMake(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="type"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Type
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="type"
                          id="type"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Articulated Dump Truck"
                          defaultValue={machineType2}
                          onChange={(event) => (
                            setMachinheType2(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="subtype"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Subtype
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="subtype"
                          id="subtype"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="6*6"
                          defaultValue={machineSubType}
                          onChange={(event) => (
                            setMachinheSubType(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="year"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Year
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="year"
                          id="year"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          min={0}
                          maxlength="4"
                          placeholder="0"
                          // defaultValue={machineYear}
                          value={machineYear}
                          onChange={(event) => (
                            handleYearChange(event.target.value),
                            setShowMsg(false)
                          )}
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="description"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={6}
                          name="description"
                          id="description"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Type here..."
                          onChange={(event) =>
                            setDiscription(event.target.value)
                          }
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex justify-center rounded-md bg-[#FCAF17] px-7 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  onClick={() => submit()}
                >
                  {addMachineLoading && (
                    <span className="fa fa-spinner fa-spin"></span>
                  )}
                  {selectedMachineid ? ` Update Machine` : ` Save Machine`}
                </button>
              </div>
              <div
                className="clr-red col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center mt-20"
                id="msg_div"
              >
                <ul>
                  {showMsg &&
                    validationMessages.map((vm) => <li key={vm}>{vm}</li>)}
                </ul>
              </div>
              {addMachineMsg && addMachineLoading === false && (
                <div>
                  {addMachineCode && addMachineCode === 200 ? (
                    <p className="success-msg ">{addMachineMsg}</p>
                  ) : addMachineMsg === "Imei already exist" ? (
                    <p className="fail-msg">
                      IMEI Number already exist, Please use diffrent IMEI
                      number.
                    </p>
                  ) : (
                    <p className="fail-msg">{addMachineMsg}</p>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};
export default AddMachine;

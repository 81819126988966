export const anomalieConstants = {
    GET_ANOMALIE_REQUEST: "GET_ANOMALIE_REQUEST",
    GET_ANOMALIE_REQUEST_STOP: "GET_ANOMALIE_REQUEST_STOP",
    GET_ANOMALIE_SUCCESS: "GET_ANOMALIE_SUCCESS",
    GET_ANOMALIE_FAILURE: "GET_ANOMALIE_FAILURE",

    UPDATE_ANOMALIE_REQUEST: "UPDATE_ANOMALIE_REQUEST",
    UPDATE_ANOMALIE_REQUEST_STOP: "UPDATE_ANOMALIE_REQUEST_STOP",
    UPDATE_ANOMALIE_SUCCESS: "UPDATE_ANOMALIE_SUCCESS",
    UPDATE_ANOMALIE_FAILURE: "UPDATE_ANOMALIE_FAILURE",
};

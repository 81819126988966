import * as config from "../config/config";
import { authHeader } from "./http.header";
export const TelematicsService = {
    getTelematicslist,
};
function getTelematicslist(
    companyType,
    foremanType,
    machineType,
    page,
    selectdMonth
) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/telematics/telematics-data/?companyType=${companyType}&foremanType=${foremanType}&machineType=${machineType}&page=${page}&selectdMonth=${selectdMonth}`,
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (response.status === 200) {
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { doc } from "prettier";
import { Dispatch, Fragment, SetStateAction } from "react";
import moment from "moment";

export function Modal({ open, setOpen, document_data }) {
  const documentData = document_data ? document_data : null
  // console.log("documentdata.... modal". documentData)
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <form>
                  <div className="space-y-6">
                    <div>
                      <div className="flex items-center justify-between pb-4">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900">
                          Document Details
                        </h2>
                        <button type="button" onClick={() => setOpen(false)}>
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>

                      <hr className="-mx-6" />

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="supplier-code"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Supplier Code
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="supplier-code"
                              id="supplier-code"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="010R"
                              value={documentData ? documentData.supplier_code : `N/A`}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-type"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Type
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="document-type"
                              id="document-type"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Purchase order"
                              value={documentData ? documentData.document_type : `N/A`}

                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-number"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Number
                          </label>
                          <div className="mt-2">
                            <input
                              disabled

                              type="text"
                              name="document-number"
                              id="document-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="C8388"
                              value={documentData ? documentData.document_no : `N/A`}

                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-date"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Date
                          </label>
                          <div className="mt-2">
                            <input
                              // type="date"
                              disabled
                              name="document-date"
                              id="document-date"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              value={documentData ? moment(
                                documentData.invoice_date
                              ).format("   MM/DD/YYYY") : `N/A`}

                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-due-date"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Due Date
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? moment(
                                documentData.invoice_due
                              ).format("   MM/DD/YYYY") : `N/A`}

                              // type="date"
                              name="document-due-date"
                              id="document-due-date"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-original-value"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Original Value
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? `(R) ${documentData.org_value}` : `N/A`}

                              type="text"
                              name="document-original-value"
                              id="document-original-value"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="L"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="document-adjustment"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Document Adjustment
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.total_adjustment : `N/A`}

                              type="text"
                              name="document-adjustment"
                              id="document-adjustment"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="EA7682 REDTOP"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="transaction-type"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Transaction Type
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.transaction : `N/A`}

                              type="text"
                              name="transaction-type"
                              id="transcation-type"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Payment"
                            />
                          </div>
                        </div>

                        {/* <div className="sm:col-span-3">
                          <label
                            htmlFor="linked-document"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Linked Document
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.document_type : `N/A`}

                              type="text"
                              name="linked-document"
                              id="linked-document"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Purchase order"
                            />
                          </div>
                        </div> */}

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="linked-document"
                            className="block text-sm leading-6 text-gray-700"
                          >
                           Description
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.mstock_des : `N/A`}

                              type="text"
                              name="linked-document"
                              id="linked-document"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Purchase order"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="linked-document"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Type
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.machine_type : `N/A`}

                              type="text"
                              name="linked-document"
                              id="linked-document"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Purchase order"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="linked-document"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Hire Type
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              value={documentData ? documentData.hire_type : `N/A`}

                              type="text"
                              name="linked-document"
                              id="linked-document"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Hire Type"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <button
                    type="button"
                    className="focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex w-full justify-center rounded-md bg-[#FCAF17] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    Save
                  </button> */}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { MachinesService } from "../../services/machine.service";
import { machineConstants } from "./types/machine.constants";
import { alertActions } from "./alert.action";

export const machineActions = {
    getMachinelist,
    getMachineTypesAndSubtypes,
    addMachine,
    getMachineMessagesById,
    getMachineType,
    getMachineImeiNo,
    getVehicleData,
    getMachineDataById,
    deleteMachineData,
};

function getMachinelist(
    selectedType,
    selectedSubtype,
    searchitem,
    company,
    pageNo
) {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachinelist(
            selectedType,
            selectedSubtype,
            searchitem,
            company,
            pageNo
        ).then(
            (machine_list) => dispatch(success(machine_list)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: machineConstants.GET_MACHINE_REQUEST };
    }
    function success(machine_list) {
        if (machine_list.code === 200) {
            return {
                type: machineConstants.GET_MACHINE_SUCCESS,
                machine_list,
            };
        } else {
            return {
                type: machineConstants.GET_MACHINE_FAILURE,
                machine_list,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_MACHINE_FAILURE, error };
    }
}

function getMachineTypesAndSubtypes() {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachineTypesAndSubtypes().then(
            (machine_type_list) => dispatch(success(machine_type_list)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: machineConstants.GET_MACHINE_TYPE_SUBTYPE_REQUEST };
    }
    function success(machine_type_list) {
        if (machine_type_list.code === 200) {
            return {
                type: machineConstants.GET_MACHINE_TYPE_SUBTYPE_SUCCESS,
                machine_type_list,
            };
        } else {
            return {
                type: machineConstants.GET_MACHINE_TYPE_SUBTYPE_FAILURE,
                machine_type_list,
            };
        }
    }
    function failure(error) {
        return {
            type: machineConstants.GET_MACHINE_TYPE_SUBTYPE_FAILURE,
            error,
        };
    }
}

function addMachine(machine_data) {
    return (dispatch) => {
        dispatch(request(machine_data));
        MachinesService.addMachine(machine_data).then(
            (machine_res) => {
                if (machine_res.code === 200) {
                    dispatch(alertActions.success(machine_res.message));
                } else {
                    dispatch(alertActions.error(machine_res.message));
                }
                dispatch(success({ machine_res }));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(machine_data) {
        return { type: machineConstants.SAVE_MACHINE_REQUEST, machine_data };
    }
    function success(machine_res) {
        if (machine_res.machine_res.code === 200) {
            return {
                type: machineConstants.SAVE_MACHINE_SUCCESS,
                machine_res,
            };
        } else {
            return {
                type: machineConstants.SAVE_MACHINE_FAILURE,
                machine_res,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.SAVE_MACHINE_FAILURE, error };
    }
}

function getMachineMessagesById() {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachineMessagesById().then(
            (mchn_msg_data) => dispatch(success(mchn_msg_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.GET_MACHINE_MESSAGE_REQUEST };
    }
    function success(mchn_msg_data) {
        if (mchn_msg_data.code === 200) {
            return {
                type: machineConstants.GET_MACHINE_MESSAGE_SUCCESS,
                mchn_msg_data,
            };
        } else {
            return {
                type: machineConstants.GET_MACHINE_MESSAGE_FAILURE,
                mchn_msg_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_MACHINE_MESSAGE_FAILURE, error };
    }
}

function getMachineType() {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachineType().then(
            (mchn_option_data) => dispatch(success(mchn_option_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.GET_MACHINE_OPTIONS_REQUEST };
    }
    function success(mchn_option_data) {
        if (mchn_option_data.code === 200) {
            return {
                type: machineConstants.GET_MACHINE_OPTIONS_SUCCESS,
                mchn_option_data,
            };
        } else {
            return {
                type: machineConstants.GET_MACHINE_OPTIONS_FAILURE,
                mchn_option_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_MACHINE_OPTIONS_FAILURE, error };
    }
}

function getMachineImeiNo(machineType) {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachineImeiNo(machineType).then(
            (mchn_option_data) => dispatch(success(mchn_option_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.GET_MACHINE_OPTIONS2_REQUEST };
    }
    function success(mchn_option_data) {
        if (mchn_option_data.code === 200) {
            return {
                type: machineConstants.GET_MACHINE_OPTIONS2_SUCCESS,
                mchn_option_data,
            };
        } else {
            return {
                type: machineConstants.GET_MACHINE_OPTIONS2_FAILURE,
                mchn_option_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_MACHINE_OPTIONS2_FAILURE, error };
    }
}

function getVehicleData(machineType, imneiNo) {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getVehicleData(machineType, imneiNo).then(
            (vehicle_data) => dispatch(success(vehicle_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.GET_VEHICLE_DATA_REQUEST };
    }
    function success(vehicle_data) {
        if (vehicle_data.code === 200) {
            return {
                type: machineConstants.GET_VEHICLE_DATA_SUCCESS,
                vehicle_data,
            };
        } else {
            return {
                type: machineConstants.GET_VEHICLE_DATA_FAILURE,
                vehicle_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_VEHICLE_DATA_FAILURE, error };
    }
}

function getMachineDataById(id) {
    return (dispatch) => {
        dispatch(request());
        MachinesService.getMachineDataById(id).then(
            (machine_data) => dispatch(success(machine_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.GET_VEHICLE_DATA_BY_ID_REQUEST };
    }
    function success(machine_data) {
        if (machine_data.code === 200) {
            return {
                type: machineConstants.GET_VEHICLE_DATA_BY_ID_SUCCESS,
                machine_data,
            };
        } else {
            return {
                type: machineConstants.GET_VEHICLE_DATA_BY_ID_FAILURE,
                machine_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.GET_VEHICLE_DATA_BY_ID_FAILURE, error };
    }
}

function deleteMachineData(id) {
    return (dispatch) => {
        dispatch(request());
        MachinesService.deleteMachineData(id).then(
            (delete_data) => dispatch(success(delete_data)),
            (error) => dispatch(failure(error.toString()))
        );
    };
    function request() {
        return { type: machineConstants.DELETE_MACHINE_REQUEST };
    }
    function success(delete_data) {
        if (delete_data.code === 200) {
            return {
                type: machineConstants.DELETE_MACHINE_SUCCESS,
                delete_data,
            };
        } else {
            return {
                type: machineConstants.DELETE_MACHINE_FAILURE,
                delete_data,
            };
        }
    }
    function failure(error) {
        return { type: machineConstants.DELETE_MACHINE_FAILURE, error };
    }
}

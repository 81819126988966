import { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { MDBBtn } from "mdb-react-ui-kit";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { telematicsActions } from "../store/actions/telematics.action";
import defimage from "../assets/icons/machines-img.svg";
import TelematicspopUp from "./TelematicsPopUp";
import PaginationCustom from "./PaginationCustom";
import PaginationPageNum from "./PaginationPageNum";
import SelectOptionDropDown from "./SelectOptionDropDown";
import DateRangePicker from "react-bootstrap-daterangepicker";
import timesheet from "../assets/icons/timesheet_icon.svg";

function Telematics() {
    const [selectdMonth, setSelectdMonth] = useState(null);
    const [companyType, setCompanyType] = useState(null);
    const [foremanType, setForemanType] = useState(null);
    const [machineType, setMachineType] = useState(null);

    const HandleSelectCompanyChange = (company) => {
        setCompanyType(company);
        dispatch(
            telematicsActions.getTelematicslist(
                company,
                foremanType,
                machineType,
                page,
                selectdMonth
            )
        );
    };

    const HandleSelectForemenChange = (foreman) => {
        setForemanType(foreman);
        dispatch(
            telematicsActions.getTelematicslist(
                companyType,
                foreman,
                machineType,
                page,
                selectdMonth
            )
        );
    };

    const HandleSelectMachineChange = (machine) => {
        setMachineType(machine);
        dispatch(
            telematicsActions.getTelematicslist(
                companyType,
                foremanType,
                machine,
                page,
                selectdMonth
            )
        );
    };

    const handlePageChange = (pageNo) => {
        if (pageNo === "-") {
            dispatch(
                telematicsActions.getTelematicsData(
                    companyType,
                    foremanType,
                    machineType,
                    page - 1,
                    selectdMonth
                )
            );
        }

        if (pageNo === "+") {
            dispatch(
                telematicsActions.getTelematicsData(
                    companyType,
                    foremanType,
                    machineType,
                    page + 1,
                    selectdMonth
                )
            );
        }
    };

    const [page, setPage] = useState(1);

    const PAGE_LIMIT = 16;
    const renderPageNumbers = () => {
        const elements = [];
        for (let i = 1; i <= noOfPages; i++) {
            elements.push(
                <PaginationPageNum
                    key={i}
                    active={page === i}
                    onClick={() => setPage(i)}
                >
                    {i}
                </PaginationPageNum>
            );
        }
        return elements;
    };
    const dispatch = useDispatch();
    const telematics_list = useSelector(
        (state) => state.TelematicsList.telematics_data
    );
    const machineList = useSelector(
        (state) => state.TelematicsList.telematics_machine
    );
    const companyList = useSelector(
        (state) => state.TelematicsList.telematics_company
    );
    const foremaList = useSelector(
        (state) => state.TelematicsList.telematics_foreman
    );
    const noOfPages = useSelector((state) => state.TelematicsList.no_of_pages);
    const dataLoading = useSelector(
        (state) => state.TelematicsList.telematics_loading
    );

    const [telematicsData, setTelematicsData] = useState(null);
    const [telematicsPopUp, setTelematicsPopUp] = useState(false);
    const openTelematicsPopUp = (telematics_data) => {
        setTelematicsPopUp(true);
        setTelematicsData(telematics_data);
    };

    const hideTelematicsPopUp = () => {
        setTelematicsPopUp(false);
    };

    const HandleMonthChange = (event) => {
        const datee = event.target.value;
        setSelectdMonth(datee);

        dispatch(
            telematicsActions.getTelematicslist(null, null, null, page, datee)
        );
    };

    const HandleClearFilters = () => {
        window.location.reload(false);

        setCompanyType(null);
        setMachineType(null);
        setForemanType(null);
        setSelectdMonth(null);
        dispatch(
            telematicsActions.getTelematicslist(null, null, null, page, null)
        );
    };

    useEffect(() => {
        dispatch(
            telematicsActions.getTelematicsData(
                companyType,
                foremanType,
                machineType,
                page,
                selectdMonth
            )
        );
    }, []);

    return (
        <>
            <NavBar />
            {telematicsPopUp && (
                <TelematicspopUp
                    hideTelematicsPopUp={hideTelematicsPopUp}
                    data={telematicsData}
                />
            )}
            <main className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row  m-top-40">
                <div className="col-sm-10 col-lg-10 col-xs-10 col-md-10 row ml-0 pr-0 pl-0 mt-20 border-box d-flex mx-auto width-70 main-div">
                    <h2 className="text-center telematics-head">Telematics</h2>
                    <div className="main__container p-0">
                        <div className="view-documentation__selects width67">
                            <div className="">
                                <SelectOptionDropDown
                                    defaultText="COMPANY"
                                    optionsList={companyList && companyList}
                                    // reset={reset}
                                />
                            </div>
                            <div className="">
                                <SelectOptionDropDown
                                    defaultText="MACHINE"
                                    optionsList={machineList && machineList}
                                    // reset={reset}
                                />
                            </div>
                            <div className=" ">
                                <SelectOptionDropDown
                                    defaultText="FOREMAN"
                                    optionsList={foremaList && foremaList}
                                    // reset={reset}
                                />
                            </div>
                            <div className="date-range">
                                <DateRangePicker
                                // onCallback={handleCallback}
                                >
                                    <button
                                        type="button"
                                        className="custom-select-container_calendar"
                                    >
                                        {"placeholder"}
                                    </button>
                                </DateRangePicker>
                            </div>
                            <div className="">
                                <MDBBtn
                                    className="reset-btns rst-btn"
                                    onClick={HandleClearFilters}
                                >
                                    Reset
                                </MDBBtn>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12  row telematics__documents d-flex mx-auto">
                            {dataLoading ? (
                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 mr-top80px col d-flex justify-content-center mt-4">
                                    <span className="fa fa-spinner fa-spin dataSourceLoader mrg-bt-60"></span>
                                </div>
                            ) : (
                                <>
                                    <div className="col-sm-10 col-lg-10 col-xs-10 col-md-10  row view-documentation__documents d-flex mx-auto gap-0">
                                        {telematics_list &&
                                            telematics_list.map((data) => (
                                                <div
                                                    className=" col-sm-3 col-lg-3 col-xs-3 col-md-3 p-0 m-0 telematics_wrapper"
                                                    onClick={() =>
                                                        openTelematicsPopUp(
                                                            data
                                                        )
                                                    }
                                                >
                                                    <div className="document-img_wrapper">
                                                        <img
                                                            src={timesheet}
                                                            alt="icon"
                                                            className="document-img_wrapper"
                                                        />
                                                    </div>
                                                    <div className="document-info">
                                                        <p className="document-text p-0 m-0 mr-b-5px mr-t-10">
                                                            {data.name}
                                                        </p>

                                                        <p className="document-text p-0 m-0 mr-b-5px">
                                                            {data.title}
                                                        </p>
                                                        <p className="document-text p-0 m-0 mr-b-5px">
                                                            {data.header}
                                                        </p>
                                                        <p className="document-text p-0 m-0 mr-b-5px">
                                                            {
                                                                data.telematics_address
                                                            }
                                                        </p>
                                                        <p className="document-text p-0 m-0 mr-b-5px">
                                                            {
                                                                data.telematics_pincode
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="txt-cntr m-top-40">
                        <PaginationCustom
                            onClickPrev={() => (
                                setPage(page - 1), handlePageChange("-")
                            )}
                            onClickNext={() => (
                                setPage(page + 1), handlePageChange("+")
                            )}
                            disabledPrev={page === 1}
                            disabledNext={page * PAGE_LIMIT >= 20}
                        >
                            {renderPageNumbers()}
                        </PaginationCustom>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Telematics;

import { useState } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
} from "mdb-react-ui-kit";

import image from "../assets/icons/machines-img1.svg";
import image2 from "../assets/images/document.png";
import AuditTable from "./TelematicsAuditTable";
import telData2 from "../assets/images/Telematics-data-2.png";
import telData from "../assets/images/Telematics-data.png";

const TelematicspopUp = (props) => {
    const [telematicspopUp, setTelematicsPopUp] = useState(true);
    const telematicsName = props.data.name;
    const telematicsTitle = props.data.title;
    const telematicsHeader = props.data.header;
    const telematicsAddress = props.data.address;
    const telematicsPincode = props.data.pincode;
    const startTime = props.data.start_time;
    const endTime = props.data.end_time;
    const odometer = props.data.odometer;
    const fuel = props.data.fuel;
    const driver = props.data.driver;
    const auditsData = props.data.audits_data;
    const [viewdocumentPage, setViewDocumentPage] = useState(true);
    const [viewAuditPage, setViewAuditPage] = useState(false);
    const [viewTemplatePage, setViewTemplatePage] = useState(false);

    const HandleDocumentActive = () => {
        setViewDocumentPage(true);
        setViewAuditPage(false);
        setViewTemplatePage(false);
    };

    const HandleAuditActive = () => {
        setViewDocumentPage(false);
        setViewAuditPage(true);
        setViewTemplatePage(false);
    };

    const HandleTemplateActive = () => {
        setViewDocumentPage(false);
        setViewAuditPage(false);
        setViewTemplatePage(true);
    };

    const toggle = () => {
        setTelematicsPopUp(!telematicspopUp);
        props.hideTelematicsPopUp(!telematicspopUp);
    };

    return (
        <MDBModal show={telematicspopUp} setShow={setTelematicsPopUp}>
            <MDBModalDialog size="lg">
                <MDBModalContent>
                    <MDBModalBody>
                        <div className=" col-sm-12 col-lg-12 col-xs-12 col-md-12  pd-tp-bt-20">
                            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row mr-b-10px">
                                <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6">
                                    <img
                                        src={image}
                                        alt="icon"
                                        className="w-25 mr-top-1"
                                        style={{ float: "right" }}
                                    ></img>
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6 text-left popup-head">
                                    <p className="p-0 m-0">{telematicsName}</p>
                                    <p className="p-0 m-0">{telematicsTitle}</p>
                                    <p className="p-0 m-0">
                                        {telematicsHeader}
                                    </p>
                                    <p className="p-0 m-0">
                                        {telematicsAddress}
                                    </p>
                                    <p className="p-0 m-0">
                                        {telematicsPincode}
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row popup-div">
                                <div className="col-sm-12 col-lg-3 col-xs-12 col-md-3 popup-sidebar">
                                    <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 mr-left-20">
                                        <p
                                            className={
                                                viewdocumentPage
                                                    ? "nav-selcted"
                                                    : "hover-underline-animation crsr-pointer "
                                            }
                                            onClick={HandleDocumentActive}
                                        >
                                            Document
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 mr-left-20">
                                        <p
                                            className={
                                                viewAuditPage
                                                    ? "nav-selcted"
                                                    : "hover-underline-animation crsr-pointer "
                                            }
                                            onClick={HandleAuditActive}
                                        >
                                            Audits
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 mr-left-1em">
                                        <p
                                            className={
                                                viewTemplatePage
                                                    ? "nav-selcted"
                                                    : "hover-underline-animation crsr-pointer "
                                            }
                                            onClick={HandleTemplateActive}
                                        >
                                            Telematics data
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 close-bt4">
                                        {/* <MDBBtn
                                            className="c_btns"
                                            onClick={toggle}
                                        >
                                            Close
                                        </MDBBtn> */}
                                        <MDBBtn
                                            className="close-btn2"
                                            onClick={toggle}
                                        >
                                            Close
                                        </MDBBtn>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-9 col-xs-12 col-md-9  ">
                                    <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12">
                                        {viewdocumentPage && (
                                            <div className="col-sm-12 col-lg-9 col-xs-12 col-md-9 ">
                                                <img
                                                    className="popup-img wdth"
                                                    src={image2}
                                                    alt="document"
                                                />
                                            </div>
                                        )}
                                        {viewTemplatePage && (
                                            <div className="col-sm-12 col-lg-9 col-xs-12 col-md-9">
                                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                                    <img
                                                        className="tel-data_img"
                                                        src={telData}
                                                        alt="telematics data"
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0 row">
                                                    <div className="col-sm-12 col-lg-6 col-xs-12 col-md-6 p-0 m-0 mt-20">
                                                        <p className="p-0 m-0">
                                                            Start time : &nbsp;{" "}
                                                            {startTime}
                                                        </p>
                                                        <p className="p-0 m-0">
                                                            End time : &nbsp;{" "}
                                                            {endTime}
                                                        </p>
                                                        <p className="p-0 m-0">
                                                            Odometer : &nbsp;{" "}
                                                            {odometer}
                                                        </p>
                                                        <p className="p-0 m-0">
                                                            Driver : &nbsp;{" "}
                                                            {driver}
                                                        </p>
                                                        <p className="p-0 m-0">
                                                            Fuel : &nbsp; {fuel}
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6 col-xs-12 col-md-6 p-0 m-0 mt-20">
                                                        <img
                                                            className="tem-image-width"
                                                            src={telData2}
                                                            alt="telematics data"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {viewAuditPage && (
                                            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12  p-0">
                                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 row m-0 audit-text">
                                                    <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 text-center">
                                                        <p className="mr-r-32">
                                                            {" "}
                                                            What
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 text-center">
                                                        <p className="mr-l-26">
                                                            {" "}
                                                            When
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 text-center">
                                                        <p className="mr-l-115">
                                                            {" "}
                                                            Who
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 p-0 m-0">
                                                    <AuditTable
                                                        data={auditsData}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default TelematicspopUp;

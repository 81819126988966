import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { timesheetActions } from "../../store/actions/timeSheet.actions";


export function VehicleCode({updatePageNo,vehicleCodeList}) {
  const vehicle_code_list = vehicleCodeList && vehicleCodeList !== 'undefined' && vehicleCodeList !== undefined ?vehicleCodeList:[]

  const dispatch = useDispatch()
  const [selected, setSelected] = useState(null);
  const handleVehicleCodeChange = (VehicleCode) => {
    updatePageNo(1)
    localStorage.setItem("VehicleCode", VehicleCode);
    dispatch(
      timesheetActions.getTimesheetlist(
        localStorage.getItem("sheetStatus")
              ? localStorage.getItem("sheetStatus")
              : null,
                localStorage.getItem("sheet_start_date")
              ? localStorage.getItem("sheet_start_date")
              : null,
          localStorage.getItem("sheet_end_date")
              ? localStorage.getItem("sheet_end_date")
              : null,
              localStorage.getItem("tms_search")
          ? localStorage.getItem("tms_search")
          : null,
          localStorage.getItem("sheetComapny")
          ? localStorage.getItem("sheetComapny")
          : null,
          1,
          VehicleCode
         
      )
  );
  };
  return (
    <Listbox value={selected} onChange={setSelected}>
    {({ open }) => (
      <>
        <div className="relative mt-2 min-w-[10rem]">
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
            <span className="flex items-center space-x-1.5 truncate">
              { !localStorage.getItem("VehicleCode") &&  (
                 <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width={20}
                 height={20}
                 fill="none"
               >
                 <path
                   fill="#657488"
                   d="m10 18.333-7.5-4.291V5.729L10 1.667l7.5 4.062v8.313L10 18.333ZM7.625 7.958c.32-.32.684-.58 1.094-.781a2.87 2.87 0 0 1 2.562 0c.41.201.775.462 1.094.781l3.188-1.875L10 3.125 4.437 6.083l3.188 1.875Zm1.75 8.584v-3.48a3.483 3.483 0 0 1-1.792-1.124A2.901 2.901 0 0 1 6.875 10c0-.153.01-.31.031-.469a2.2 2.2 0 0 1 .115-.469L3.75 7.125v6.188l5.625 3.229ZM10 11.875c.528 0 .972-.18 1.333-.542.361-.36.542-.805.542-1.333 0-.528-.18-.972-.542-1.333A1.812 1.812 0 0 0 10 8.125c-.528 0-.972.18-1.333.542A1.812 1.812 0 0 0 8.125 10c0 .528.18.972.542 1.333.36.361.805.542 1.333.542Zm.625 4.667 5.625-3.23V7.125l-3.27 1.937c.055.153.093.31.114.47.02.159.031.315.031.468 0 .736-.236 1.382-.708 1.938-.473.555-1.07.93-1.792 1.124v3.48Z"
                 />
               </svg>
              )}
              <span>{localStorage.getItem("VehicleCode")
                      ? localStorage.getItem("VehicleCode")
                      : "Vehicle Code"}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>

          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          > 
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
             
              {vehicle_code_list&& vehicle_code_list.length>0 ?vehicle_code_list.map((item) => (

                <Listbox.Option
                  title={item.label}
                  key={item.label}
                  className={({ active }) =>
                    clsx(
                      active ? "bg-gray-50" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={item.value}
                  onClick={() => handleVehicleCodeChange(item.value)}
                >

                  {({ selected }) => (
                    <div className="flex items-center space-x-2">
                      <span
                        className={clsx(
                          selected && localStorage.getItem("VehicleCode") ? "bg-[#FCAF17]" : "bg-gray-200",
                          "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                        )}
                      />
                      <span
                        className={clsx(
                          selected && localStorage.getItem("VehicleCode") ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {item.value}
                      </span>
                      
                    </div>
                  )}
                </Listbox.Option>
                
              )):null}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
  );
}

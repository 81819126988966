import React, { useState, useEffect } from "react";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import logo from "../images/logo.png";
import user from "../images/user.jpg";
import userSecond from "../images/user-icon1.png";

import { NavLink, Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import EditProfileIcon from "../assets/icons/editProfileIcon";
import SettingIcon from "../assets/icons/Settingicon";
import MyProfileIcon from "../assets/icons/myProfileIcon";
import HelpIcon from "../assets/icons/helpIcon";
import LogoutIcon from "../assets/icons/logoutIcon";
import { history } from "../helpers/history";
import { userActions } from "../store/actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { MDBIcon } from "mdb-react-ui-kit";

function NavBar() {
    const dispatch = useDispatch();
    const [path, setPath] = useState("/");
    const user_dt = useSelector((state) => state.users);
    const [userRole, setUserRole] = useState(null);
    const [isTimesheetOpen, setIsTimesheetOpen] = useState(false);
    const [isMachineOpen, setIsMachineOpen] = useState(false);

    const logOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_role");
        history.push("/login");
        window.location.href = "/login";
    };

    useEffect(() => {
        setPath(window.location.pathname);
        dispatch(userActions.getUserData());
        setUserRole(
            localStorage.getItem("user_role")
                ? localStorage.getItem("user_role")
                : null
        );
    }, []);

    return (
        <>
            <main className="top-border-box nav_header">
                <section className="header__content ">
                    <Link to="/">
                        <img
                            className="logo m-l-34"
                            src={logo}
                            alt="Timesheet"
                        />
                    </Link>
                    <Nav className="header__nav ">
                        <ul className="nav__list nav-wdth">
                            <li>
                                <NavLink
                                    className={
                                        path === "/"
                                            ? "nav-selcted"
                                            : "hover-underline-animation"
                                    }
                                    to="/"
                                >
                                    Dashboard
                                </NavLink>
                            </li>

                            {userRole
                                ? (userRole === "CFO" ||
                                      userRole === "Contractor") && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/view-document"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/view-document"
                                          >
                                              Finance Data
                                          </NavLink>
                                      </li>
                                  )
                                : null}
                            {userRole
                                ? (userRole === "Contractor" ||
                                      userRole === "CFO") && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/reports"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/reports"
                                          >
                                              Reports
                                          </NavLink>
                                      </li>
                                  )
                                : null}

                            {/* {userRole
                                ? userRole ===
                                      "Contractor" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/upload"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/upload"
                                          >
                                              Upload
                                          </NavLink>
                                      </li>
                                  )
                                : null} */}
                            {/* {userRole
                                ? userRole ===
                                      "Contractor" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/anomalies"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/anomalies"
                                          >
                                              Anomalies
                                          </NavLink>
                                      </li>
                                  )
                                : null} */}

                            {/* {userRole
                                ? userRole === "Contractor" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/save-timesheet"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/save-timesheet"
                                          >
                                              Add Timesheet
                                          </NavLink>
                                      </li>
                                  )
                                : null} */}
                            {userRole
                                ? (userRole === "Approver" ||
                                      userRole === "Contractor") && (
                                      <li>
                                          <Popup
                                              className="menu-popup-top2"
                                              trigger={
                                                  //   <button className="menu-btn">
                                                  //       {" "}
                                                  //       <i
                                                  //           className="fa fa-angle-down down-ico"
                                                  //           aria-hidden="true"
                                                  //       ></i>
                                                  //   </button>
                                                  <div
                                                      className={
                                                          path ===
                                                              "/list-timesheet" ||
                                                          path ===
                                                              "/save-timesheet"
                                                              ? "nav-selcted crsr-pointer"
                                                              : "hover-underline-animation crsr-pointer nav-hover"
                                                      }

                                                      //   to="/list-timesheet"
                                                  >
                                                      <span
                                                          onClick={() => {
                                                              setIsTimesheetOpen(
                                                                  !isTimesheetOpen
                                                              );
                                                          }}
                                                      >
                                                          Timesheet
                                                          {/* <button className="menu-btn"> */}
                                                          {isTimesheetOpen ? (
                                                              <MDBIcon
                                                                  fas
                                                                  icon="caret-up down-icon"
                                                              />
                                                          ) : (
                                                              <MDBIcon
                                                                  fas
                                                                  icon="caret-down down-icon"
                                                              />
                                                          )}
                                                      </span>
                                                      {/* </button> */}
                                                  </div>
                                              }
                                              position="bottom"
                                          >
                                              {userRole
                                                  ? userRole !== "CFO" &&
                                                    userRole !== "Approver" && (
                                                        <>
                                                            <div className="crsr-pointer-menu2 ">
                                                                <NavLink
                                                                    to="/save-timesheet"
                                                                    className={
                                                                        path ===
                                                                        "/save-timesheet"
                                                                            ? "nav-selcted crsr-pointer crsr-pointer-menu3"
                                                                            : "hover-underline-animation crsr-pointer crsr-pointer-menu3"
                                                                    }
                                                                >
                                                                    Add
                                                                    Timesheet
                                                                </NavLink>
                                                            </div>
                                                        </>
                                                    )
                                                  : null}
                                              <div className="crsr-pointer-menu2 mr-b-8px ">
                                                  <NavLink
                                                      to="/list-timesheet"
                                                      className={
                                                          path ===
                                                          "/list-timesheet"
                                                              ? "nav-selcted crsr-pointer crsr-pointer-menu3"
                                                              : "hover-underline-animation crsr-pointer crsr-pointer-menu3"
                                                      }
                                                  >
                                                      View Timesheet
                                                  </NavLink>
                                              </div>
                                          </Popup>
                                      </li>
                                  )
                                : null}

                            {userRole
                                ? (userRole === "Approver" ||
                                      userRole === "Contractor") && (
                                      <li>
                                          <Popup
                                              className="menu-popup-top2"
                                              trigger={
                                                  //   <button className="menu-btn">
                                                  //       {" "}
                                                  //       <i
                                                  //           className="fa fa-angle-down down-ico"
                                                  //           aria-hidden="true"
                                                  //       ></i>
                                                  //   </button>
                                                  <div
                                                      className={
                                                          path ===
                                                              "/machines" ||
                                                          path ===
                                                              "/add-machine"
                                                              ? "nav-selcted crsr-pointer"
                                                              : "hover-underline-animation crsr-pointer nav-hover"
                                                      }
                                                      //   to="/list-timesheet"
                                                  >
                                                      <span
                                                          onClick={() => {
                                                              setIsMachineOpen(
                                                                  !isMachineOpen
                                                              );
                                                          }}
                                                      >
                                                          Machines
                                                          {/* <button className="menu-btn"> */}
                                                          {isMachineOpen ? (
                                                              <MDBIcon
                                                                  fas
                                                                  icon="caret-up down-icon"
                                                              />
                                                          ) : (
                                                              <MDBIcon
                                                                  fas
                                                                  icon="caret-down down-icon"
                                                              />
                                                          )}
                                                      </span>
                                                      {/* </button> */}
                                                  </div>
                                              }
                                              position="bottom"
                                          >
                                              {userRole
                                                  ? userRole !== "CFO" &&
                                                    userRole !== "Approver" && (
                                                        <>
                                                            <div className="crsr-pointer-menu2 ">
                                                                <NavLink
                                                                    to="/add-machine"
                                                                    className={
                                                                        path ===
                                                                        "/add-machine"
                                                                            ? "nav-selcted crsr-pointer crsr-pointer-menu3"
                                                                            : "hover-underline-animation crsr-pointer crsr-pointer-menu3"
                                                                    }
                                                                >
                                                                    Add Machines
                                                                </NavLink>
                                                            </div>
                                                        </>
                                                    )
                                                  : null}
                                              <div className="crsr-pointer-menu2 mr-b-8px ">
                                                  <NavLink
                                                      to="/machines"
                                                      className={
                                                          path === "/machines"
                                                              ? "nav-selcted crsr-pointer crsr-pointer-menu3"
                                                              : "hover-underline-animation crsr-pointer crsr-pointer-menu3"
                                                      }
                                                  >
                                                      View Machines
                                                  </NavLink>
                                              </div>
                                          </Popup>
                                      </li>
                                  )
                                : null}
                            {userRole
                                ? userRole === "CFO" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/list-timesheet"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/list-timesheet"
                                          >
                                              View Timesheets
                                          </NavLink>
                                      </li>
                                  )
                                : null}

                            {userRole
                                ? userRole === "CFO" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/machines"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/machines"
                                          >
                                              View Machines
                                          </NavLink>
                                      </li>
                                  )
                                : null}
                            {userRole
                                ? userRole === "CFO" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/anomalies"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/anomalies"
                                          >
                                              Anomalies
                                          </NavLink>
                                      </li>
                                  )
                                : null}

                            {/* {userRole
                                ? (userRole === "Approver" ||
                                      userRole === "Contractor") && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/add-machine"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/add-machine"
                                              onClick={() => {
                                                  window.location.assign(
                                                      "/add-machine"
                                                  );
                                              }}
                                          >
                                             
                                              Add Machine
                                          </NavLink>
                                      </li>
                                  )
                                : null} */}
                            {/* {userRole
                                ? userRole ===
                                      "Contractor" && (
                                      <li>
                                          <NavLink
                                              className={
                                                  path === "/telematics"
                                                      ? "nav-selcted"
                                                      : "hover-underline-animation"
                                              }
                                              to="/telematics"
                                          >
                                              Telematics
                                          </NavLink>
                                      </li>
                                  )
                                : null} */}
                        </ul>
                    </Nav>
                    <div className="header__user">
                        <p className="user__name">
                            <span>
                                {user_dt.items !== undefined
                                    ? user_dt.items.name
                                    : "Anil G."}
                            </span>
                        </p>
                        <img
                            src={userSecond}
                            alt="Timesheet"
                            height={50}
                            width={50}
                        />
                        <p className="user__name menu-popup-top">
                            <Popup
                                className="menu-popup-top"
                                trigger={
                                    <button className="menu-btn">
                                        {" "}
                                        <i
                                            className="fa fa-bars fnt-24"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                }
                                position="bottom"
                            >
                                {/* <div className="crsr-pointer-menu">
                                    <MyProfileIcon /> My Profile
                                </div> */}
                                {/* <div className="crsr-pointer-menu">
                                    {" "}
                                    <EditProfileIcon />
                                    Edit Profile
                                </div> */}
                                <div className="crsr-pointer-menu">
                                    {" "}
                                    <Nav.Link href="/change-password">
                                        <SettingIcon /> Settings
                                    </Nav.Link>
                                </div>
                                {/* <div className="crsr-pointer-menu">
                                    <HelpIcon />
                                    Help
                                </div> */}
                                <div
                                    className="crsr-pointer-menu"
                                    onClick={logOut}
                                >
                                    <LogoutIcon />
                                    <span>Logout</span>
                                </div>
                            </Popup>
                        </p>
                        <div></div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default NavBar;

import { timesheetConstants } from "../actions/types/timeSheet.constants";

export function timesheet(state = {}, action) {
  switch (action.type) {
    case timesheetConstants.TIMESHEET_REQUEST:
      return {
        timesheet_loading: true,
        alert_type: action.type,
        message: null,
      };
    case timesheetConstants.TIMESHEET_SUCCESS:
      return {
        data: action.timesheet_res.timesheet_res.data,
        timesheet_loading: false,
        alert_type: action.type,
        message: null,
      };
    case timesheetConstants.TIMESHEET_FAILURE:
      return {
        timesheet_loading: false,
        alert_type: action.type,
        message: action.timesheet_res.timesheet_res.message,
      };
    default:
      return state;
  }
}
export function timesheetSubmit(state = {}, action) {
  switch (action.type) {
    case timesheetConstants.UPDATE_TIMESHEET_STATUS_REQUEST:
      return {
        timesheet_submit_loading: true,
        alert_type: action.type,
        message: null,
      };
    case timesheetConstants.UPDATE_TIMESHEET_STATUS_SUCCESS:
      return {
        data: action.tmsht_res.tmsht_res.data,
        timesheet_submit_loading: false,
        alert_type: action.type,
        message: null,
      };
    case timesheetConstants.UPDATE_TIMESHEET_STATUS_FAILURE:
      return {
        timesheet_submit_loading: false,
        alert_type: action.type,
        message: "Something went wrong, Please try again in some time",
      };
    default:
      return state;
  }
}

export function TimesheetList(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.GET_TIMESHEET_REQUEST:
      return {
        timesheet_loading: true,
        data: null,
      };
    case timesheetConstants.GET_TIMESHEET_SUCCESS:
      return {
        data: actions.timesheet_list.data["timesheet_list"],
        no_of_pages: actions.timesheet_list.data["no_of_pages"],
        total: actions.timesheet_list.data["total_count"],
        offset: actions.timesheet_list.data["offset"],
        upto: actions.timesheet_list.data["upto"],
        timesheet_loading: false,
      };
    case timesheetConstants.GET_TIMESHEET_FAILURE:
      return {
        timesheet_loading: false,
        data: null,
      };
    default:
      return state;
  }
}

export function Base64toJsonata(state = {}, action) {
  switch (action.type) {
    case timesheetConstants.GET_BASE64_REQUEST:
      return {
        base64_loading: true,
        alert_type: action.type,
      };
    case timesheetConstants.GET_BASE64_SUCCESS:
      return {
        data: action.base64_res.base64_res.data,
        base64_loading: false,
        alert_type: action.type,
      };
    case timesheetConstants.GET_BASE64_FAILURE:
      return {
        base64_loading: false,
        alert_type: action.type,
      };
    default:
      return state;
  }
}

export function SaveFileResults(state = {}, action) {
  switch (action.type) {
    case timesheetConstants.FILEDATA_REQUEST:
      return {
        file_loading: true,
        alert_type: action.type,
      };
    case timesheetConstants.FILEDATA_SUCCESS:
      return {
        data: action.file_res.file_res.data,
        file_loading: false,
        alert_type: action.type,
        code: action.file_res.file_res.code,
      };
    case timesheetConstants.FILEDATA_FAILURE:
      return {
        file_loading: false,
        alert_type: action.type,
      };
    default:
      return state;
  }
}

export function OptionsList(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.GET_OPTIONS_REQUEST:
      return {
        options_list_loading: true,
        data: null,
      };
    case timesheetConstants.GET_OPTIONS_SUCCESS:
      return {
        machine_list: actions.options_list.data["machine_list"],
        machine_sub_list: actions.options_list.data["machine_sub_list"],
        vehicle_code_list: actions.options_list.data["vehicle_code_list"],
        vehicle_code_list2: actions.options_list.data["vehicle_code_list2"],
        site_name_list: actions.options_list.data["site_name_list"],
        site_name_list2: actions.options_list.data["site_name_list2"],
        machine_list2: actions.options_list.data["machine_list2"],
        machine_sub_list2: actions.options_list.data["machine_sub_list2"],
        po_list: actions.options_list.data["po_list"],
        po_list2: actions.options_list.data["po_list2"],
        options_list_loading: false,
      };
    case timesheetConstants.GET_OPTIONS_FAILURE:
      return {
        options_list_loading: false,
        data: null,
      };
    default:
      return state;
  }
}

export function SheetDataById(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.GET_SHEET_BY_ID_REQUEST:
      return {
        sheet_data_loading: true,
        data: null,
      };
    case timesheetConstants.GET_SHEET_BY_ID_SUCCESS:
      return {
        data: actions.sheet_data.data["sheet_data"],
        days_data: actions.sheet_data.data["days_data"],
        sheet_data_loading: false,
      };
    case timesheetConstants.GET_SHEET_BY_ID_FAILURE:
      return {
        sheet_data_loading: false,
        data: null,
      };
    default:
      return state;
  }
}

export function updateTimesheetData(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.UPDATE_TIMESHEET_REQUEST:
      return {
        sheet_saved: false,
        sheet_status_loading: true,
      };
    case timesheetConstants.UPDATE_TIMESHEET_SUCCESS:
      return {
        sheet_saved: true,
        sheet_status_loading: false,
      };
    case timesheetConstants.UPDATE_TIMESHEET_FAILURE:
      return {
        sheet_saved: false,
        sheet_status_loading: false,
      };
    default:
      return state;
  }
}

export function AuditOrApproveSheet(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.SHEET_APPROVE_AUDIT_REQUEST:
      return {
        audit_or_approve: false,
        audit_or_approve_loading: true,
      };
    case timesheetConstants.SHEET_APPROVE_AUDIT_SUCCESS:
      return {
        audit_or_approve: true,
        audit_or_approve_loading: false,
      };
    case timesheetConstants.SHEET_APPROVE_AUDIT_FAILURE:
      return {
        audit_or_approve: false,
        audit_or_approve_loading: false,
      };
    default:
      return state;
  }
}

export function getMessagesById(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.GET_MESSAGE_REQUEST:
      return {
        data: null,
        msg_loading: true,
      };
    case timesheetConstants.GET_MESSAGE_SUCCESS:
      return {
        data: actions.msg_data.data["data_list"],
        sheet_counts: actions.msg_data.data["sheet_count"],
        msg_loading: false,
      };
    case timesheetConstants.GET_MESSAGE_FAILURE:
      return {
        data: null,
        msg_loading: false,
      };
    default:
      return state;
  }
}

export function getDashBoardGraphData(state = {}, actions) {
  switch (actions.type) {
    case timesheetConstants.DASHBOARD_GRAPH_REQUEST:
      return {
        graph_data: null,
        graph_data_loading: true,
        cfo_pie_chart: null,
        finance_chart: null,
        cfo_line_chart: null,
      };
    case timesheetConstants.DASHBOARD_GRAPH_SUCCESS:
      return {
        graph_data: actions.graph_data.data,
        cfo_pie_chart: actions.graph_data.data.cfo_pie_chart,
        finance_chart: actions.graph_data.data.finance_chart,
        cfo_line_chart: actions.graph_data.data.cfo_line_chart,
        graph_data_loading: false,
      };
    case timesheetConstants.DASHBOARD_GRAPH_FAILURE:
      return {
        graph_data: null,
        graph_data_loading: false,
        cfo_pie_chart: null,
        finance_chart: null,
        cfo_line_chart: null,
      };
    default:
      return state;
  }
}

export const machineConstants = {
    GET_VEHICLE_DATA_REQUEST: "GET_VEHICLE_DATA_REQUEST",
    GET_VEHICLE_DATA_REQUEST_STOP: "GET_VEHICLE_DATA_REQUEST_STOP",
    GET_VEHICLE_DATA_SUCCESS: "GET_VEHICLE_DATA_SUCCESS",
    GET_VEHICLE_DATA_FAILURE: "GET_VEHICLE_DATA_FAILURE",

    GET_VEHICLE_DATA_BY_ID_REQUEST: "GET_VEHICLE_DATA_BY_ID_REQUEST",
    GET_VEHICLE_DATA_BY_ID_REQUEST_STOP: "GET_VEHICLE_DATA_BY_ID_REQUEST_STOP",
    GET_VEHICLE_DATA_BY_ID_SUCCESS: "GET_VEHICLE_DATA_BY_ID_SUCCESS",
    GET_VEHICLE_DATA_BY_ID_FAILURE: "GET_VEHICLE_DATA_BY_ID_FAILURE",

    DELETE_MACHINE_SUCCESS: "DELETE_MACHINE_SUCCESS",
    DELETE_MACHINE_REQUEST: "DELETE_MACHINE_REQUEST",
    DELETE_MACHINE_FAILURE: "DELETE_MACHINE_FAILURE",

    SAVE_MACHINE_SUCCESS: "SAVE_MACHINE_SUCCESS",
    SAVE_MACHINE_REQUEST: "SAVE_MACHINE_REQUEST",
    SAVE_MACHINE_FAILURE: "SAVE_MACHINE_FAILURE",

    GET_MACHINE_REQUEST: "GET_MACHINE_REQUEST",
    GET_MACHINE_REQUEST_STOP: "GET_MACHINE_REQUEST_STOP",
    GET_MACHINE_SUCCESS: "GET_MACHINE_SUCCESS",
    GET_MACHINE_FAILURE: "GET_MACHINE_FAILURE",

    GET_MACHINE_TYPE_SUBTYPE_REQUEST: "GET_MACHINE_TYPE_SUBTYPE_REQUEST",
    GET_MACHINE_TYPE_SUBTYPE_REQUEST_STOP:
        "GET_MACHINE_TYPE_SUBTYPE_REQUEST_STOP",
    GET_MACHINE_TYPE_SUBTYPE_SUCCESS: "GET_MACHINE_TYPE_SUBTYPE_SUCCESS",
    GET_MACHINE_TYPE_SUBTYPE_FAILURE: "GET_MACHINE_TYPE_SUBTYPE_FAILURE",

    GET_MACHINE_OPTIONS_REQUEST: "GET_MACHINE_OPTIONS_REQUEST",
    GET_MACHINE_OPTIONS_REQUEST_STOP: "GET_MACHINE_OPTIONS_REQUEST_STOP",
    GET_MACHINE_OPTIONS_SUCCESS: "GET_MACHINE_OPTIONS_SUCCESS",
    GET_MACHINE_OPTIONS_FAILURE: "GET_MACHINE_OPTIONS_FAILURE",

    GET_MACHINE_OPTIONS2_REQUEST: "GET_MACHINE_OPTIONS2_REQUEST",
    GET_MACHINE_OPTIONS2_REQUEST_STOP: "GET_MACHINE_OPTIONS2_REQUEST_STOP",
    GET_MACHINE_OPTIONS2_SUCCESS: "GET_MACHINE_OPTIONS2_SUCCESS",
    GET_MACHINE_OPTIONS2_FAILURE: "GET_MACHINE_OPTIONS2_FAILURE",

    GET_MACHINE_MESSAGE_REQUEST: "GET_MACHINE_MESSAGE_REQUEST",
    GET_MACHINE_MESSAGE_REQUEST_STOP: "GET_MACHINE_MESSAGE_REQUEST_STOP",
    GET_MACHINE_MESSAGE_SUCCESS: "GET_MACHINE_MESSAGE_SUCCESS",
    GET_MACHINE_MESSAGE_FAILURE: "GET_MACHINE_MESSAGE_FAILURE",
};

import { combineReducers } from "redux";
import {
    timesheet,
    TimesheetList,
    Base64toJsonata,
    SaveFileResults,
    SheetDataById,
    updateTimesheetData,
    AuditOrApproveSheet,
    getMessagesById,
    getDashBoardGraphData,
    OptionsList,
    timesheetSubmit,
} from "./timesheet.reducer";
import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import {
    MachineList,
    MachineTypesList,
    AddMachine,
    getMachineMessagesById,
    getMachineType,
    getMachineImeiNo,
    getVehicleData,
    getMachineDataById,
    DeleteMachineDataById,
} from "./machine.reducer";
import { DocumentList } from "./document.reducer";
import { AnomalieList, UpdatedAnomalies } from "./anomalies.reducer";
import { TelematicsList } from "./telematics.reducer";
const rootReducer = combineReducers({
    authentication,
    timesheet,
    TimesheetList,
    Base64toJsonata,
    SaveFileResults,
    users,
    MachineList,
    AnomalieList,
    UpdatedAnomalies,
    TelematicsList,
    OptionsList,
    SheetDataById,
    updateTimesheetData,
    AuditOrApproveSheet,
    getMessagesById,
    getDashBoardGraphData,
    MachineTypesList,
    AddMachine,
    getMachineMessagesById,
    getMachineType,
    getMachineImeiNo,
    getVehicleData,
    DocumentList,
    getMachineDataById,
    DeleteMachineDataById,
    timesheetSubmit,
});

export default rootReducer;

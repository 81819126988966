// function Base64ToImage({ base64String }) {
//   let myArray = base64String.split("%2C").map((str) => str.trim());
//   //   const imageUrl = `data:image/png;base64,${myArray[1]}`;
//   if (base64String.length < 100 || myArray.length > 1) {
//     myArray = [];
//   }

//   return <img src={myArray} />;
// }

// export default Base64ToImage;

function Base64ToImage({ base64String, cls }) {
  let myArray = base64String.split("%2C").map((str) => str.trim());
  //   const imageUrl = `data:image/png;base64,${myArray[1]}`;
  if (base64String.length < 100 || myArray.length > 1) {
    myArray = [];
  }

  return <img className={cls === true && "new-pad"} src={myArray} />;
}

export default Base64ToImage;

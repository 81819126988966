import DataTable from "react-data-table-component";
import { datatableCustomStyles2 } from "../dataTableStyle";
const AuditTable = (props) => {
    const audit_data = props.data;
    const TableColumns = [
        {
            name: "#",
            minWidth: "70px",
            maxWidth: "70px",
            selector: "name",
            sortable: false,
            center: false,
            cell: (row) => (
                <div className="input-text-cstm">
                    <pre className="wrd-break input-text-cstm">{row.id}</pre>
                </div>
            ),
        },
        {
            name: "First",
            minWidth: "170px",
            maxWidth: "170px",
            selector: "name",
            sortable: false,
            center: false,
            cell: (row) => (
                <div className="input-text-cstm">
                    <pre className="wrd-break input-text-cstm">{row.what}</pre>
                </div>
            ),
        },
        {
            name: "Last",
            minWidth: "160px",
            maxWidth: "160px",
            selector: "name",
            sortable: false,
            center: false,
            cell: (row) => (
                <div className="input-text-cstm">
                    <pre className="wrd-break input-text-cstm">{row.when}</pre>
                </div>
            ),
        },
        {
            name: "Handle",
            minWidth: "150px",
            maxWidth: "150px",
            selector: "name",
            sortable: false,
            center: false,
            cell: (row) => (
                <div className="input-text-cstm">
                    <pre className="wrd-break input-text-cstm">{row.who}</pre>
                </div>
            ),
        },
    ];
    return (
        <DataTable
            noHeader
            columns={TableColumns}
            pagination={false}
            keyField={"id"}
            paginationPerPage={10}
            data={audit_data}
            customStyles={datatableCustomStyles2}
        />
    );
};

export default AuditTable;

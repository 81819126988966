import { useState, useEffect } from "react";
import UploadForm from "./UploadForm";
import VarifiedFile from "./Verified";
import UploadingStep from "./UploadingStep";
import NavBar from "./NavBar";
import UploadProcess from "./UploadScanProcess";
// import { useSelector } from "react-redux";


const Upload = (props) => {
  // const base64_data  = useSelector((state) => state.Base64toJsonata);

  // const [pngData ,setPngData] = useState(null)

  const activeStep = "steps__wrapper active";
  const nonActiveStep = "steps__wrapper";

  const [sending, setSending] = useState(false);

  const [filled, setFilled] = useState(0);

  const [verified, setVerified] = useState(false);

  if (filled === 100) {
    setTimeout(() => {
      setVerified(true);
    }, 50);
  }

  useEffect(() => {
    if (filled < 100 && sending) {
      setTimeout(() => setFilled((prev) => (prev += 2)), 150);
    }
  }, [filled, sending]);

  // function to handle when the user submits the form
  let [dataSub, setDataSub] = useState({});

  let [size, setSize] = useState("size");

  let [name, setName] = useState("name");

  


  async function handleFormData(data) {
    setDataSub(data);
    setName(data.name);
    setSize(Math.round(data.size * 0.000001 * 100) / 100); // rounding to hundredths
    setSending(true);
    const formData = new FormData();
    formData.append("file", data);
  }

  return (
    <>
    <NavBar />
    <main className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row  m-top-40">
    <div className="col-sm-10 col-lg-10 col-xs-10 col-md-10 row mr-0 ml-0 pr-0 pl-0 mt-20 border-box d-flex mx-auto width-70 ">
    <div className="main__container">

      {!verified ? (
        <>
       
         <h1 className='page__title'>Lorem Ipsum is simply dummy text</h1>
         <p className='page__under-title__text'>Select relevant documents to complete your kyc</p>
          {!sending ? <UploadForm submit={handleFormData} /> : <UploadProcess fileName={name} fileSize={size !== "size" ? size + " MB" : size} filled={filled} sending={sending} />}
        </>
      ) : (
        <VarifiedFile 
          title = {name}
          />
      )}

      <section className='main__steps'>
        <UploadingStep className={activeStep} number='1' title='Select Type' />
        <UploadingStep className={sending ? activeStep : nonActiveStep} number='2' title='Upload File' />
        <UploadingStep className={filled < 50 ? nonActiveStep : activeStep} number='3' title='Scan File' />
        <UploadingStep className={verified ? activeStep : nonActiveStep} number='4' title='Verify' />
      </section>
      </div>
      </div>
      </main>
    </>
  );
};

export default Upload;

import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { documentActions } from "../../store/actions/document.actions";
const years = [
  { id: 1, name: "2023" },
  { id: 2, name: "2022" },
  { id: 3, name: "2021" },
];

export function Year({year_list,updatePageNo}) {
  const dispatch = useDispatch()
  const yearList = year_list ? year_list : []
  const [selected, setSelected] = useState(null);
  const handleYearChange = (selcted_year) => {
    updatePageNo(1);
    localStorage.setItem("year", selcted_year);

    dispatch(
      documentActions.getDocumentlist(
        localStorage.getItem("file_type")
          ? localStorage.getItem("file_type")
          : null,
        localStorage.getItem("docComapny")
          ? localStorage.getItem("docComapny")
          : null,
        localStorage.getItem("foreman")
          ? localStorage.getItem("foreman")
          : null,
        localStorage.getItem("doc-machine")
          ? localStorage.getItem("doc-machine")
          : null,
        1,
        localStorage.getItem("start_date")
                    ? localStorage.getItem("start_date")
                    : null,
                localStorage.getItem("end_date")
                    ? localStorage.getItem("end_date")
                    : null,
        selcted_year,
        localStorage.getItem("doc_search")
          ? localStorage.getItem("doc_search")
          : null
      )
    );
  };
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[10rem]">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FCAF17] sm:text-sm sm:leading-6">
              <span className="flex items-center space-x-1.5 truncate">
                {!localStorage.getItem("year")  && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                  >
                    <path
                      fill="#657488"
                      d="M4.5 18c-.412 0-.766-.15-1.06-.448A1.452 1.452 0 0 1 3 16.5v-11c0-.403.147-.753.44-1.052A1.433 1.433 0 0 1 4.5 4H6V2h1.5v2h5V2H14v2h1.5c.412 0 .766.15 1.06.448.293.299.44.65.44 1.052v11c0 .403-.147.753-.44 1.052A1.433 1.433 0 0 1 15.5 18h-11Zm0-1.5h11V9h-11v7.5Zm0-9h11v-2h-11v2Zm5.504 4.5a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.071-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.072.39-.214.535a.714.714 0 0 1-.532.219Zm-3.25 0a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.071-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.071.39-.214.535a.714.714 0 0 1-.532.219Zm6.5 0a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.072-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.072.39-.214.535a.714.714 0 0 1-.532.219Zm-3.25 3a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.071-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.072.39-.214.535a.714.714 0 0 1-.532.219Zm-3.25 0a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.071-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.071.39-.214.535a.714.714 0 0 1-.532.219Zm6.5 0a.736.736 0 0 1-.535-.214.714.714 0 0 1-.219-.532c0-.21.072-.39.214-.535a.714.714 0 0 1 .532-.219c.21 0 .39.072.535.214.146.143.219.32.219.532 0 .21-.072.39-.214.535a.714.714 0 0 1-.532.219Z"
                    />
                  </svg>
                )}
                <span>{localStorage.getItem("year")
          ? localStorage.getItem("year")  : "Year"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {yearList && yearList.length>0 ?yearList.map((item) => (
                  <Listbox.Option
                    title={item.label}
                    key={item.label}
                    className={({ active }) =>
                      clsx(
                        active ? "bg-gray-50" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                    onClick={() =>handleYearChange(item.value)}

                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <span
                          className={clsx(
                            selected && localStorage.getItem("year") ? "bg-[#FCAF17]" : "bg-gray-200",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                        />
                        <span
                          className={clsx(
                            selected && localStorage.getItem("year") ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.value}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                )):null}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

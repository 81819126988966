import { AnomalieService } from "../../services/anomalies.service";
import { anomalieConstants } from "../actions/types/anomalies.constants";
import { alertActions } from "./alert.action";
export const anomalieActions = {
  getAnomalielist,
  updateAnomaliesStatus,
};

function getAnomalielist(
  siteType,
  docType,
  statusType,
  start_date,
  end_date,
  comapny,
  page,
  anom_search
) {
  return (dispatch) => {
    dispatch(request());
    AnomalieService.getAnomalielist(
      siteType,
      docType,
      statusType,
      start_date,
      end_date,
      comapny,
      page,
      anom_search
    ).then(
      (anomalie_list) => dispatch(success(anomalie_list)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: anomalieConstants.GET_ANOMALIE_REQUEST };
  }
  function success(anomalie_list) {
    if (anomalie_list.code === 200) {
      return {
        type: anomalieConstants.GET_ANOMALIE_SUCCESS,
        anomalie_list,
      };
    } else {
      return {
        type: anomalieConstants.GET_ANOMALIE_FAILURE,
        anomalie_list,
      };
    }
  }
  function failure(error) {
    return { type: anomalieConstants.GET_ANOMALIE_FAILURE, error };
  }
}

function updateAnomaliesStatus(anom_data, anoml_id) {
  let a_dict = {
    anom_data: anom_data,
    anoml_id: anoml_id,
  };

  return (dispatch) => {
    dispatch(request({ a_dict }));
    AnomalieService.updateAnomaliesStatus(anom_data, anoml_id).then(
      (anom_res) => {
        if (anom_res.code === 200) {
          dispatch(alertActions.success(anom_res.message));
        } else {
          dispatch(alertActions.error(anom_res.message));
        }
        dispatch(success({ anom_res }));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(a_dict) {
    return { type: anomalieConstants.UPDATE_ANOMALIE_REQUEST, a_dict };
  }
  function success(anom_res) {
    if (anom_res.anom_res.code === 200) {
      return {
        type: anomalieConstants.UPDATE_ANOMALIE_SUCCESS,
        anom_res,
      };
    } else {
      return {
        type: anomalieConstants.UPDATE_ANOMALIE_FAILURE,
        anom_res,
      };
    }
  }
  function failure(error) {
    return { type: anomalieConstants.UPDATE_ANOMALIE_FAILURE, error };
  }
}

import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import SignaturePad from "react-signature-canvas";
import { Calendar, DateObject } from "react-multi-date-picker";
import { MDBContainer } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import NavBar from "./NavBar";
import Base64ToImage from "./base64ToImg";
const TimeSheetTableEdit = () => {
  const [isSubmittable, setIsSubmittable] = useState(true);
  const [timeSheetData, setTimesheetData] = useState([]);
  const selectedsheetid = window.location.href.split("id=")[1];
  const sheetData = useSelector((state) => state.SheetDataById.data);
  const daysData = useSelector((state) => state.SheetDataById.days_data);
  const data_saved = useSelector(
    (state) => state.updateTimesheetData.sheet_saved
  );

  const sheet_status_loading = useSelector(
    (state) => state.updateTimesheetData.sheet_status_loading
  );

  const [isDateDataset, setIsDateDataset] = useState(false);

  const [showSgnPad, setShowSgnPad] = useState(false);

  const rateList = [
    {
      key: "Dry",
      value: "Dry",
    },
    {
      key: "Wet",
      value: "Wet",
    },
  ];

  const periodList = [
    { key: "Day", value: "Day" },
    { key: "Night", value: "Night" },
    { key: "24 Hours", value: "24 hours" },
  ];

  const [hrsVal, setHrsVal] = useState(null);
  const [hrsDate, setHrsDate] = useState(null);

  const handlehourschange = (e, date) => {
    let val = e.target.value;

    if (!val || val.match(/^\d{1,}(\.\d{0,1})?$/)) {
      setHrsVal(val);
    }

    if (val.toString().length === 0) {
      val = null;
    }

    setTimeout(() => {
      handleTimeSheetChange(e.target.name, val, date);
    }, 1000);

    setHrsDate(date);
  };

  const MachineList = [
    "Articulated Dump Truck",
    "Hydraulic Excavator",
    "Excavator",
    "Dozer",
    "Front End Loader",
    "Grader",
    "Water Tanker",
    "Articulated Dump Truck - Water Tanker",
    "Long Reach Excavator",
  ];
  const PoList = [
    "RTB02933",
    "KW03924",
    "DW03598",
    "EW10975",
    "KW03920",
    "DW03608",
    "KW03919",
    "W02484",
    "DW03603",
    "KW03910",
    "DW03612",
    "EW10979",
    "KW03925",
    "DW03517",
    "DW03514",
    "AK695",
    "KW03931",
    "DW03496",
    "KW03916",
    "DW03599",
    "KW03926",
    "EW10956",
    "EW10760",
    "DW03601",
    "DW03609",
    "DW03628",
    "DW03600",
    "RTB02926",
    "DW03701",
    "DW03583",
    "DW03709",
    "DW03610",
    "DW03630",
    "RTB02931",
    "DW03572",
    "DW03487",
    "DW03445",
    "DW03277",
    "DW03357",
    "DW03516",
    "DW03513",
    "DW03512",
    "DW03556",
    "KW03930",
    "KW03921",
    "KW03918",
    "KW03917",
    "KW03922",
    "KW03933",
    "KW03929",
    "KW03935",
    "KW03909",
    "RTB02939",
    "KW03928",
    "KW03923",
    "KW03837",
    "DW03503",
    "DW03498",
    "DW2964",
    "DW03497",
    "DW03552",
    "DW03502",
    "DW03724",
    "DW03343",
    "DW03500",
    "DW03504",
    "EW10980",
    "EW10981",
    "EW10955",
    "EW10947",
    "EW10982",
    "EW11077",
    "W02155",
    "W02483",
    "DW03714",
  ];
  const [MachineListOptions, setMachineListoptions] = useState(MachineList);
  const [PoListOptions, setPoListOptions] = useState(PoList);

  const handleInputMachineChange = ({ target }) => {
    if (target.value) {
      const filteredOptions = MachineList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase())
      );
      setMachineListoptions(filteredOptions);
    } else {
      setMachineListoptions(MachineListOptions);
    }
    setMachine(target.value);
    if (
      target.value === null ||
      target.value === undefined ||
      target.value.length === 0
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const handleinvoiceChange = (target) => {
    setInvoice(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const handleordernumberChange = (target) => {
    setOrderNumber(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const handlecostcenterChange = (target) => {
    setCostCenter(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const handlemanagernameChange = (target) => {
    setManagerName(target);
    if (target === null || target === undefined || target.length === 0) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };

  const handleInputPOChange = ({ target }) => {
    if (target.value) {
      const filteredOptions = PoList.filter((option) =>
        option.toLowerCase().startsWith(target.value.toLowerCase())
      );
      setPoListOptions(filteredOptions);
    } else {
      setPoListOptions(PoListOptions);
    }
    setPo(target.value);
    if (
      target.value === null ||
      target.value === undefined ||
      target.value.length === 0
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }
  };
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [signature, setSignature] = useState(null);
  const [signatureImg, setSignatureImg] = useState(null);

  const savesignature = () => {
    setSignature(sigCanvas.current.toDataURL());
  };
  const clearSignature = () => {
    setShowSgnPad(true);
    sigCanvas.current.clear();
    setSignature(null);
    setValidationMessages([]);

    setIsSubmittable(false);
  };
  const handleSignature = () => {
    savesignature();

    setIsSubmittable(true);
  };
  const timesheet_loading = useSelector(
    (state) => state.timesheet.timesheet_loading
  );
  const timesheet_id = useSelector((state) => state.timesheet.data);
  let newDate = new Date();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  const [firstDate, setFirstDate] = useState(`${year}/${month}/1`);
  const alert_type = useSelector((state) => state.timesheet.alert_type);
  const [alertMsg, setAlertMsg] = useState(false);
  const sigCanvas = useRef({});
  const dispatch = useDispatch();
  const [validationMessages, setValidationMessages] = useState([]);
  const [isMonthChanged, setIsMonthChanged] = useState(false);

  const [machine, setMachine] = useState(null);
  const [sheetMsg, setSheetMsg] = useState(null);

  const company = "Red Top Asset Management";
  const [orderNumber, setOrderNumber] = useState(null);

  const [costCenter, setCostCenter] = useState(null);
  const [managerName, setManagerName] = useState(null);
  const [sheetStatus, setSheetStatus] = useState(null);
  const [foreman, setForeman] = useState(null);

  const [po, setPo] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [daysPerMonth, setDaysPerMonth] = useState(null);
  const [perDay, setPerDay] = useState(null);
  const [numericMonth, setNumericMonth] = useState(month);
  const [numericyear, setNumericyear] = useState(year);
  const [updateDisable, setUpdateDisable] = useState(false);

  function getDaysInMonth(year, month) {
    setTimesheetData([]);
    let dateArry = [];
    let len = 0;
    len = new Date(year, month, 0).getDate();
    setDaysPerMonth(len);
    for (let i = 1; i <= len; i++) {
      let monthLong = new Date(
        String(year + "/" + month + "/" + i)
      ).toLocaleDateString("en-us", { month: "long" });
      let dayLong = new Date(
        String(year + "/" + month + "/" + i)
      ).toLocaleDateString("en-us", { weekday: "long" });
      dateArry.push({
        day: dayLong,
        date:
          i +
          "-" +
          monthLong.toString().substring(0, 3) +
          "-" +
          year.toString(),
        hours: null,
        rate: "Dry",
        period: "Day",
      });
    }
    setTimesheetData(dateArry);
  }

  const handleTimeSheetChange = (name, value, date) => {
    let updatedList = timeSheetData.map((item) => {
      if (item.date === date) {
        return { ...item, [name]: value };
      }

      return item;
    });
    setTimesheetData(updatedList);
    const total = updatedList.reduce(function (cnt, o) {
      return (
        cnt +
        (o.hours !== null && o.hours !== "null" && o.hours !== ""
          ? parseFloat(o.hours)
          : 0)
      );
    }, 0);
    if (
      total === null ||
      total === undefined ||
      total === 0 ||
      total.toString() === "NaN"
    ) {
      setIsSubmittable(false);
    } else {
      setIsSubmittable(true);
    }

    setTotalHours(total);
    setPerDay(
      parseFloat(total / parseInt(sheetData["days_per_month"])).toFixed(2)
    );
  };
  const handleMonthChange = (event) => {
    setPerDay(null);
    setTotalHours(null);
    setAlertMsg(false);
    setIsMonthChanged(true);
    setTimesheetData([]);
    setFirstDate(event);
    var selectedYear = new Date(String(event)).toLocaleDateString("en-us", {
      year: "numeric",
    });
    var selectedMonth = new Date(String(event)).toLocaleDateString("en-us", {
      month: "numeric",
    });
    getDaysInMonth(selectedYear, selectedMonth);
    setNumericyear(selectedYear);
    setNumericMonth(selectedMonth);
  };

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setForeman(value);
      if (value === null || value === undefined || value.length === 0) {
        setIsSubmittable(false);
      } else {
        setIsSubmittable(true);
      }
    }
  };

  if (isDateDataset === false) {
    if (daysData) {
      setTimesheetData(daysData);
      setIsDateDataset(true);
    }
    if (sheetData) {
      setSheetMsg(sheetData["sheet_message"]);
      setMachine(sheetData["machine"]);
      setForeman(sheetData["foreman"]);
      setSignatureImg(sheetData["signature"]);
      setPo(sheetData["po"]);
      setInvoice(sheetData["invoice"]);
      setOrderNumber(sheetData["order_number"]);
      setCostCenter(sheetData["cost_center"]);
      setPerDay(sheetData["per_day"]);
      setDaysPerMonth(sheetData["days_per_month"]);
      setTotalHours(sheetData["total_hours"]);
      setManagerName(sheetData["manager_name"]);
      setUpdateDisable(sheetData["is_approved"]);
    }
  }

  const submit = () => {
    let dataDict = {
      sheet_data: {
        machine: machine ? machine : sheetData["machine"],
        company: company ? company : sheetData["company"],
        foreman: foreman ? foreman : sheetData["foreman"],
        po: po ? po : sheetData["po"],
        cost_center: costCenter ? costCenter : sheetData["cost_center"],
        order_number: orderNumber ? orderNumber : sheetData["order_number"],
        viewer_id: parseInt(sheetData["viewer"]),
        manager_name: managerName ? managerName : sheetData["manager_name"],
        signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
        invoive: invoice ? invoice : sheetData["invoice"],
        status: sheetData["status"],
        total_hours: totalHours ? totalHours : sheetData["total_hours"],
        total: totalHours ? totalHours : sheetData["total_hours"],
        per_day: perDay ? perDay : sheetData["per_day"],
        days_per_month: daysPerMonth
          ? daysPerMonth
          : sheetData["days_per_month"],

        year: sheetData["year"],
        month: sheetData["month"],
        month_year: sheetData["month_year"],
      },
      days_data: timeSheetData,
    };

    let messages = [];
    // if (!selectedViewer) {
    //     messages.push("Viewer is required");
    // }

    if (!machine) {
      messages.push("Machine is required");
    }
    if (!costCenter) {
      messages.push("Cost Center is required");
    }
    if (!orderNumber) {
      messages.push("Reference Number is required");
    }
    if (!managerName) {
      messages.push("Name is required");
    }
    if (!company) {
      messages.push("Company is required");
    }
    if (!foreman) {
      messages.push("Foreman is required");
    }
    if (!invoice) {
      messages.push("Invoice is required");
    }
    if (!po) {
      messages.push("P.O is required");
    }

    if (timeSheetData.length > 0) {
      let count = 0;
      timeSheetData.map((item) => {
        if (item.hours !== null && item.hours !== "null" && item !== 0) {
          count += 1;
          return count;
        }
      });
      if (count === 0) {
        messages.push("Hours is required");
      }
    }
    if (messages.length > 0) {
      const error_violation = document.getElementById("msg_div");
      window.scrollTo({
        top: error_violation.offsetTop,
        behavior: "smooth",
      });
      setValidationMessages(messages);
    } else {
      setIsMonthChanged(false);
      setAlertMsg(true);
      setValidationMessages([]);
      dispatch(timesheetActions.updateTimesheetData(dataDict, selectedsheetid));
      if (timesheet_id && isMonthChanged === false) {
        setAlertMsg(false);
        setIsAlertVisible(true);
        setTimeout(() => {
          setIsAlertVisible(false);
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (selectedsheetid) {
      dispatch(timesheetActions.getSheetById(selectedsheetid));
    }
    if (sheetData) {
      getDaysInMonth(sheetData["year"], sheetData["month"]);
    }
  }, []);

  return (
    <>
      <NavBar />
      <main className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row m-top-40 m-0 p-0">
        {sheetMsg && (
          <div className="msg-notification col-sm-10 col-lg-10 col-xs-10 col-md-10 wdth-85 mx-auto ml-0 mr-0 mt-20 pl-0  pr-0 text-center ">
            {sheetMsg}
          </div>
        )}

        <div className="col-sm-10 col-lg-10 col-xs-10 col-md-10 row border-box d-flex wdth-85 mx-auto  ml-0 mr-0 mt-20 pl-0 pr-0">
          <MDBContainer>
            <div className="mt-4 text-center ">
              <h2 className="clr-gray text-center">
                <p className="m-0 p-0">Enter Timesheet</p>
              </h2>

              <div className="d-flex justify-content-center">
                {sheetData && (
                  <Calendar
                    currentDate={
                      new DateObject({
                        year: sheetData["year"],
                        month: sheetData["month"],
                      })
                    }
                    disabled
                  />
                )}
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row d-flex justify-content-center clr-gray m-0 p-0">
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="machine">Machine</label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style wdth-240"
                    type="text"
                    list="machines"
                    defaultValue={machine}
                    onChange={handleInputMachineChange}
                  />
                  <datalist id="machines">
                    {MachineListOptions.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="company">Company</label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style"
                    type="text"
                    list="companies"
                    defaultValue={company}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="forman">Foreman</label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style"
                    type="text"
                    id="foreman"
                    name="foreman"
                    defaultValue={foreman}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row d-flex justify-content-center clr-gray m-0 p-0">
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="po">Purchase Order Number</label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style wdth-240"
                    type="text"
                    list="pos"
                    defaultValue={po}
                    onClick={handleInputPOChange}
                    onChange={handleInputPOChange}
                  />
                  <datalist id="pos">
                    {PoListOptions.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label className="" htmlFor="invoice">
                    Invoice Number
                  </label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style"
                    type="text"
                    id="invoice"
                    name="invoice"
                    defaultValue={invoice}
                    onChange={(event) =>
                      handleinvoiceChange(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label className="" htmlFor="status">
                    Status
                  </label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style"
                    type="text"
                    id="status"
                    name="status"
                    disabled
                    defaultValue={sheetData && sheetData["status"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row d-flex justify-content-center clr-gray mrg-bt-60 m-0 p-0">
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="order_number">Order Number </label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style wdth-240"
                    type="text"
                    id="order_number"
                    name="order_number"
                    defaultValue={orderNumber}
                    onChange={(event) =>
                      handleordernumberChange(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 ">
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <label htmlFor="company">Cost Center</label>
                </div>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 m-0 p-0">
                  <input
                    className="input-style"
                    type="text"
                    id="cost_center"
                    name="cost_center"
                    defaultValue={costCenter}
                    onChange={(event) =>
                      handlecostcenterChange(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-xs-12 col-md-12 m-0 mb-2 mt-2 p-0 "></div>
            </div>
            {timeSheetData.length > 0 && (
              <>
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 row mlr-5p m-0  mt-20 p-0">
                  <MDBTable responsive>
                    <MDBTableHead>
                      <tr>
                        <th className="bg-lght-gray table-timesheet pb-2  pt-2 text-center">
                          DAY
                        </th>
                        <th className="bg-lght-gray table-timesheet pb-2 pt-2 text-center ">
                          DATE
                        </th>
                        <th className="bg-lght-gray table-timesheet pb-2 pt-2 text-center">
                          HOURS
                        </th>
                        <th className="bg-lght-gray table-timesheet pb-2 pt-2 text-center">
                          RATE
                        </th>
                        <th className="bg-lght-gray table-timesheet pb-2 pt-2 text-center">
                          PERIOD
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {timeSheetData.map((Data, index) => (
                        <tr key={index} className="table-timesheet">
                          <td className="table-timesheet pb-2 pt-2 text-center ">
                            {Data.day}
                          </td>
                          <td className="table-timesheet width-15 pb-2 pt-2 text-center">
                            {Data.date}
                          </td>
                          <td className="table-timesheet pb-2 pt-2 text-center ">
                            <input
                              className="small-input form-control table-input-box d-flex width-57 mx-auto"
                              type="number"
                              name="hours"
                              placeholder="Eg : 4.5"
                              key={Data.hours}
                              value={
                                Data.date === hrsDate ? hrsVal : Data.hours
                              }
                              onChange={(event) =>
                                handlehourschange(event, Data.date)
                              }
                            />
                          </td>
                          <td className="table-timesheet wdth-25 pb-2 pt-2 text-center">
                            <select
                              className="form-control table-input-box styled-select d-flex mx-auto"
                              name="rate"
                              value={Data.rate}
                              onChange={(event) =>
                                handleTimeSheetChange(
                                  event.target.name,
                                  event.target.value,
                                  Data.date
                                )
                              }
                            >
                              {rateList.map((item, key) => (
                                <option value={item.value} key={key}>
                                  {item.value}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="wdth-25 pb-2 pt-2 text-center">
                            <select
                              className="form-control table-input-box d-flex mx-auto"
                              name="period"
                              value={Data.period}
                              onChange={(event) =>
                                handleTimeSheetChange(
                                  event.target.name,
                                  event.target.value,
                                  Data.date
                                )
                              }
                            >
                              {periodList.map((data, key) => (
                                <option key={key} value={data.key}>
                                  {data.value}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 tr-fnt-size pb-2 pt-2">
                          TOTAL HOURS
                        </td>
                        <td className="table-timesheet pb-2 pt-2 text-center">
                          {/* {totalHours
                                                        ? totalHours
                                                        : sheetData[
                                                              "total_hours"
                                                          ]} */}
                          {/* {sheetData["total_hours"]} */}
                          {totalHours}
                        </td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                      </tr>
                      <tr>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 tr-fnt-size pb-2 pt-2">
                          DAYS PER MONTH
                        </td>
                        <td className="table-timesheet pb-2 pt-2 text-center">
                          {daysPerMonth}
                        </td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                      </tr>
                      <tr>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 tr-fnt-size pb-2 pt-2">
                          PER DAY
                        </td>
                        <td className="table-timesheet pb-2 pt-2 text-center">
                          {perDay}
                        </td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                      </tr>
                      <tr>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 tr-fnt-size pb-2 pt-2">TOTAL</td>
                        <td className="table-timesheet pb-2 pt-2 text-center">
                          {totalHours}
                        </td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                        <td className="brdr-0 pb-2 pt-2"></td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12  row mt-20">
                  <div className="col-sm-12 col-md-12 col-lg-6 float-right">
                    {updateDisable !== true ? (
                      <MDBBtn
                        onClick={() => submit()}
                        className={"updt-btn"}
                        type="submit"
                      >
                        {sheet_status_loading && (
                          <span className="fa fa-spinner fa-spin"></span>
                        )}
                        {timesheet_loading && (
                          <span className="fa fa-spinner fa-spin"></span>
                        )}
                        Update
                      </MDBBtn>
                    ) : null}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 mr-top-155">
                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 sgn-img">
                      {/* {(signatureImg ===
                                                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC") &
                                                setShowSgnPad} */}
                      {showSgnPad ||
                      signatureImg ===
                        "/data%3Aimage/png%3Bbase64%2CiVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" ? (
                        <SignaturePad
                          onChange={handleSignature}
                          ref={sigCanvas}
                          canvasProps={{
                            className: "signatureCanvas",
                          }}
                        />
                      ) : (
                        <Base64ToImage base64String={signatureImg} />
                      )}
                      {/* <SignaturePad
                                                onChange={handleSignature}
                                                ref={sigCanvas}
                                                canvasProps={{
                                                    className:
                                                        "signatureCanvas",
                                                }}
                                            /> */}
                      {/* <Base64ToImage
                                                base64String={signatureImg}
                                            /> */}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                      <div className="signature-style">
                        <span className="pr-2">SIGNATURE</span>
                      </div>
                      <div className="">
                        <span
                          className="clear-btn"
                          type="clearSignature"
                          id="clearSignature"
                          onClick={clearSignature}
                        >
                          Clear
                        </span>
                      </div>

                      <input
                        placeholder=" Name"
                        className="input-style2 mt-3 p-2"
                        type="text"
                        id="manager_name"
                        name="manager_name"
                        defaultValue={managerName}
                        onChange={(event) =>
                          handlemanagernameChange(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="clr-red col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center mt-20"
              id="msg_div"
            >
              <ul>
                {validationMessages.map((vm) => (
                  <li key={vm}>{vm}</li>
                ))}
              </ul>
            </div>

            {alertMsg === true &&
              data_saved === true &&
              sheet_status_loading === false && (
                <div>
                  <p className="success-msg ">Timesheet Updated Successfully</p>
                </div>
              )}

            {data_saved === false && sheet_status_loading === false && (
              <div>
                <p className="fail-msg">
                  Something went wrong, Please try again
                </p>
              </div>
            )}
          </MDBContainer>
        </div>
      </main>
    </>
  );
};

export default TimeSheetTableEdit;

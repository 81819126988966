// 13831cc3247a2481ada98ef61c691214

import { useEffect, useState } from "react";
import React from "react";
import { MDBContainer } from "mdbreact";
import NavBar from "./NavBar";
import { history } from "../helpers/history";
import Clock from "react-clock";
import { MDBIcon } from "mdb-react-ui-kit";
import "react-clock/dist/Clock.css";
import { userActions } from "../store/actions/user.actions";
import { machineActions } from "../store/actions/machine.action";
import { useDispatch, useSelector } from "react-redux";
import { timesheetActions } from "../store/actions/timeSheet.actions";
import moment from "moment";
import Nav from "react-bootstrap/Nav";
import axios from "axios";
import {
  convertToInternationalCurrencySystem,
  formatCurrency,
} from "../helpers/Helper";
import { apiUrl } from "../config/config";
import SideBar from "./SideBar";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import ReactApexChart from "react-apexcharts";
import FinanceData from "../assets/images/FinanceData1.png";
import Anomalies from "../assets/images/Anomalies.png";

const Dashboard = () => {
  const pie = {
    series: [50, 50],
    options: {
      width: 380,
      type: "pie",
      labels: ["Not Urgent", "Urgent"],
      responsive: [
        {
          breakpoint: 720,
          options: {
            chart: {
              width: 300,
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -30,
            minAngleToShowLabel: 30,
          },
        },
      },
      legend: {
        position: "bottom",
      },
      colors: ["#FCAF17", "#E34747"],
    },
  };

  const moveToTimesheet = (tm_url) => {
    window.location.assign(tm_url);
  };

  const moveToCFOTimesheet = (id, type) => {
    window.location.assign(`/list-timesheet/?id=${id}`);

    // window.location.assign(tm_url);
  };

  const moveToListTimesheet = (ltm_url) => {
    window.location.assign(ltm_url);
  };
  const moveToListUrgentAnomalies = () => {
    window.location.assign(`/anomalies/?status=${"Urgent"}`);
  };
  const sheetCounts = useSelector(
    (state) => state.getMessagesById.sheet_counts
  );
  const msg_loading = useSelector((state) => state.getMessagesById.msg_loading);
  const tdyDate = new Date();
  const crntDate = String(tdyDate.getDate()).padStart(2, "0");
  const crntMonth = tdyDate.toLocaleString("default", { month: "short" });
  const crntDay = tdyDate.toLocaleString("default", { weekday: "long" });

  const msgData = useSelector((state) => state.getMessagesById.data);
  const cfo_pie_chart = useSelector(
    (state) => state.getDashBoardGraphData.cfo_pie_chart
  );

  const finance_chart = useSelector(
    (state) => state.getDashBoardGraphData.finance_chart
  );
  // console.log("hereeeeeeeeee", finance_chart)
  const graph_data_loading = useSelector(
    (state) => state.getDashBoardGraphData.graph_data_loading
  );
  const cfo_line_chart = useSelector(
    (state) => state.getDashBoardGraphData.cfo_line_chart
  );
  const mchnMsgData = useSelector((state) => state.getMachineMessagesById.data);
  const dispatch = useDispatch();
  const [clockValue, setClockValue] = useState(new Date());

  const user_dt = useSelector((state) => state.users);
  let widgetArr = [1, 2];

  const getMyLocation = () => { };

  const stats = [
    {
      name: "Total Timesheets",
      stat: sheetCounts ? formatCurrency(sheetCounts["total_sheets"]) : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/list-timesheet`,
    },
    {
      name: "Approved Timesheets",
      stat: sheetCounts ? formatCurrency(sheetCounts["approved_sheets"]) : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/list-timesheet/?status=Approved`,
    },
    {
      name: "Timesheets Awaiting Approval",
      stat: sheetCounts ? formatCurrency(sheetCounts["pending_sheets"]) : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/list-timesheet/?status=Pending`,
    },
    {
      name: "Awaiting Clarification",
      stat: sheetCounts
        ? formatCurrency(sheetCounts["clarification_sheets"])
        : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/list-timesheet/?status=Request Clarification`,
    },
    // {
    //   name: "Total Machines Logged",
    //   stat: sheetCounts ? formatCurrency(sheetCounts["total_machines"]) : 0,
    //   previousStat: "0",
    //   change: "0",
    //   changeType: "increase",
    //   pagePath: `/machines`,
    // },
    // {
    //   name: "Data Available",
    //   stat: sheetCounts ? formatCurrency(sheetCounts["total_documents"]) : 0,
    //   previousStat: "0",
    //   change: "0",
    //   changeType: "increase",
    //   pagePath: `/view-document`,
    // },
  ];

  const cfostats = [
    {
      name: "Number of Total Anomalies",
      stat: sheetCounts ? formatCurrency(sheetCounts["anom_count"]) : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/anomalies`,
    },
    {
      name: "Urgent Anomalies",
      stat: sheetCounts ? formatCurrency(sheetCounts["urgent_anomalies"]) : 0,
      previousStat: "0",
      change: "0",
      changeType: "increase",
      pagePath: `/anomalies`,
    },
  ];
  const line = {
    series: [
      {
        name: "Desktops",
        data: cfo_line_chart ? cfo_line_chart["data_list"] : [],
      },
    ],

    options: {
      chart: {
        height: 310,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: cfo_line_chart ? cfo_line_chart["date_list"] : [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
        },
      },
      dataLabels: {
        enabled: false,
      },

      colors: ["#FCAF17"],
    },
  };
  const area = {
    legend: {
      show: true,
    },
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Finance Data",
        data: finance_chart ? finance_chart["data_list"] : [],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
      shape: "circle",
    },

    stroke: {
      show: true,
      curve: "smooth",
      colors: undefined,
      width: 2,
    },
    grid: {
      row: {
        opacity: 0,
      },
    },
    xaxis: {
      categories: finance_chart ? finance_chart["month_list"] : [],
    },
    yaxis: {
      show: true,
    },
    fill: {
      type: "solid",
      opacity: [0.05, 0],
    },
    colors: ["#FCAF17", "#E34747"],
    legend: {
      position: "bottom",
      markers: {
        radius: 12,
        offsetX: -4,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 20,
      },
    },
  };
  const [monthCount, setMonthCount] = useState(12);
  const getFinanceChartData = (month_count) => {
    dispatch(timesheetActions.getDashBoardGraphData(month_count));
    setMonthCount(month_count);
  };
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    setUserRole(
      localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null
    );
    dispatch(userActions.getUserData());
    getMyLocation();

    if (user_dt) {
      dispatch(timesheetActions.getMessagesById());
      dispatch(machineActions.getMachineMessagesById());
      dispatch(timesheetActions.getDashBoardGraphData(12));
    }

    history.push("/");

    const interval = setInterval(() => setClockValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };

    // if (user_dt){

    // }
  }, []);

  return (
    <div className="flex">
      <SideBar />
      {user_dt &&
        user_dt.items !== undefined &&
        user_dt.items.is_uptodate === false
        ? window.location.assign("/change-password?period=expired")
        : null}
      <main className="w-full min-w-[70rem] overflow-x-auto bg-gray-100 py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center">
              <div className="space-y-4 sm:flex-auto">
                <h1 className="w-[35rem] text-3xl font-semibold leading-6 text-gray-900">
                  {/* {user_dt.items !== undefined
                    ? userRole !== "CFO"
                      ? user_dt.items.company_name +
                        " (" +
                        userRole +
                        ")"
                      : user_dt.items.name +
                        " (" +
                        userRole +
                        ")"
                    : null}{" "} */}
                  {/* {user_dt.items !== undefined
                    ? userRole !== "CFO"
                      ? user_dt.items.company_name
                      : user_dt.items.name
                    : null}{" "} */}
                  {user_dt.items !== undefined
                    ? userRole !== "CFO"
                      ? user_dt.items.name
                      : user_dt.items.name
                    : null}{" "}
                </h1>
                <p className="text-sm text-gray-500">
                  Welcome{" "}
                  {user_dt.items !== undefined ? user_dt.items.name : null}{" "}
                  {/* {user_dt.items !== undefined ? `( ${userRole} ) ` : null} */}
                  {user_dt.items !== undefined ? userRole === "CFO" ? `( Management ) ` : `( ${userRole} ) ` : null}

                  👋, here's what's new.
                </p>
              </div>
              <div className="mt-4 lg:ml-16 lg:mt-0 lg:flex-none">
                <div className="flex items-center space-x-1">
                  {/* <div className="min-w-[20rem]">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </div> */}
                  {/* <button
                    type="button"
                    className="inline-block rounded-md border-0 bg-white px-3 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="none"
                    >
                      <path
                        fill="#657488"
                        d="M3.333 15.833v-1.25h1.75V8.208c0-1.166.344-2.204 1.032-3.114a4.444 4.444 0 0 1 2.718-1.719v-.604c0-.32.115-.583.344-.792.23-.208.504-.312.823-.312.32 0 .594.104.823.312.23.209.344.472.344.792v.604a4.488 4.488 0 0 1 2.729 1.719c.694.91 1.041 1.948 1.041 3.114v6.375h1.73v1.25H3.333Zm6.667 2.5c-.444 0-.833-.163-1.167-.49a1.588 1.588 0 0 1-.5-1.176h3.334c0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49Zm-3.667-3.75h7.354V8.208c0-1.027-.354-1.902-1.062-2.625-.708-.722-1.576-1.083-2.604-1.083s-1.9.361-2.615 1.083c-.715.723-1.073 1.598-1.073 2.625v6.375Z"
                      />
                    </svg>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="my-5">
              <div>
                {/* <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Last 30 days
                </h3> */}
                <dl className="mt-5 grid grid-cols-4 gap-5">
                  {stats.map((item) => (
                    <div
                      key={item.name}
                      className="crsr-pointer overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                      onClick={() => {
                        moveToListTimesheet(item.pagePath);
                      }}
                    >
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {item.name}
                      </dt>
                      <dd className="mt-1 flex items-center space-x-5 text-3xl font-semibold tracking-tight text-gray-900">
                        <span>{item.stat}</span>
                        <div
                          className={clsx(
                            item.changeType === "increase"
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800",
                            "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                          )}
                        >
                          {item.changeType === "increase" ? (
                            <ArrowUpIcon
                              className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowDownIcon
                              className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                              aria-hidden="true"
                            />
                          )}

                          <span className="sr-only">
                            {" "}
                            {item.changeType === "increase"
                              ? "Increased"
                              : "Decreased"}{" "}
                            by{" "}
                          </span>
                          {item.change}
                        </div>
                      </dd>
                    </div>
                  ))}

                  {/* {user_dt.items !== undefined
                    ? userRole === "CFO" &&
                      cfostats.map((item) => (
                        <div
                          key={item.name}
                          className="crsr-pointer overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                          onClick={() => {
                            moveToListTimesheet(item.pagePath);
                          }}
                        >
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {item.name}
                          </dt>
                          <dd className="mt-1 flex items-center space-x-5 text-3xl font-semibold tracking-tight text-gray-900">
                            <span>{item.stat}</span>
                            <div
                              className={clsx(
                                item.changeType === "increase"
                                  ? "bg-green-100 text-green-800"
                                  : "bg-red-100 text-red-800",
                                "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                              )}
                            >
                              {item.changeType === "increase" ? (
                                <ArrowUpIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ArrowDownIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {" "}
                                {item.changeType === "increase"
                                  ? "Increased"
                                  : "Decreased"}{" "}
                                by{" "}
                              </span>
                              {item.change}
                            </div>
                          </dd>
                        </div>
                      ))
                    : null} */}
                </dl>
              </div>
            </div>
            <ul role="list" className="my-8 grid w-full grid-cols-3 gap-8">
              <li className="mx-hgt-400 col-span-2 grid w-full grid-cols-2 gap-8">
                {user_dt && user_dt.items !== undefined ? (
                  userRole === "CFO" ? (
                    <>
                      <div className="mx-hgt-400 col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                        <div className="flex w-full flex-col">
                          <h4 className="p-6 text-2xl">Anomalies</h4>
                          <div className="flex w-full items-center justify-center">
                            {cfo_pie_chart ? (
                              <ReactApexChart
                                options={pie.options}
                                series={cfo_pie_chart.series}
                                type="pie"
                                width={380}
                              />
                            ) : graph_data_loading ? (
                              <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center pt-3 ">
                                <span className="fa fa-spinner fa-spin "></span>
                              </div>
                            ) : null}
                            {/* <img className="" src={Anomalies} alt="login" /> */}
                          </div>
                        </div>
                      </div>
                      <div className=" mx-hgt-400 col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                        <div className="flex w-full flex-col">
                          <h4 className="p-6 text-2xl">Anomalies Trends</h4>
                          <div className="w-full px-12">
                            {cfo_line_chart ? (
                              <ReactApexChart
                                options={line.options}
                                series={line.series}
                                height={line.options.chart.height}
                                type="line"
                              />
                            ) : graph_data_loading ? (
                              <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center pt-3 ">
                                <span className="fa fa-spinner fa-spin "></span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null
                ) : null}

                <div
                  className="col-span-2 divide-y divide-gray-200 rounded-lg bg-white shadow"
                  style={{ maxHeight: "430px" }}
                >
                  <div className="flex w-full flex-col">
                    <div className="overflow-hidden rounded-xl border border-gray-200 bg-white">
                      <div className="p-6">
                        <div className="flex flex-wrap items-center justify-between">
                          <h4 className="text-2xl">Number Of Purchase Orders Invoiced</h4>

                          <nav className="mt-4 flex items-center justify-center space-x-1 sm:space-x-2 md:mt-0 lg:order-3 lg:mt-4 2xl:order-2 2xl:mt-0">
                            <a
                              href="#"
                              title=""
                              className={
                                monthCount === 12
                                  ? "dration-200 rounded-lg border border-gray-900 px-2 py-2 text-xs font-bold text-gray-900 transition-all hover:bg-gray-100 sm:px-4"
                                  : "dration-200 rounded-lg border border-transparent px-2 py-2 text-xs font-bold text-gray-500 transition-all hover:bg-gray-100 sm:px-4"
                              }
                              onClick={() => {
                                getFinanceChartData(12);
                              }}
                            >
                              {" "}
                              12 Months{" "}
                            </a>

                            <a
                              href="#"
                              title=""
                              className={
                                monthCount === 6
                                  ? "dration-200 rounded-lg border border-gray-900 px-2 py-2 text-xs font-bold text-gray-900 transition-all hover:bg-gray-100 sm:px-4"
                                  : "dration-200 rounded-lg border border-transparent px-2 py-2 text-xs font-bold text-gray-500 transition-all hover:bg-gray-100 sm:px-4"
                              }
                              onClick={() => {
                                getFinanceChartData(6);
                              }}
                            >
                              {" "}
                              6 Months{" "}
                            </a>
                          </nav>
                          {/* <img className="" src={FinanceData} alt="login" /> */}
                        </div>

                        <div id="chart4" className="mt-6">
                          {finance_chart ? (
                            <ReactApexChart
                              options={area}
                              series={area.series}
                              type={area.chart.type}
                              height={area.chart.height}
                            />
                          ) : graph_data_loading ? (
                            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center pt-3 ">
                              <span className="fa fa-spinner fa-spin "></span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <h2 className="p-6 text-2xl font-semibold leading-6 text-gray-900">
                  Notifications
                </h2>
                {msgData && msgData.length > 0 ? (
                  msgData.slice(0, 15).map((data) => (
                    <div className="crsr-pointer flex w-full items-center justify-between space-x-6 p-6">
                      <div className="relative rounded-full bg-[#FCAF17] bg-opacity-20 p-3">
                        <div className="absolute right-0.5 top-0.5 h-3 w-3 rounded-full bg-[#FCAF17]"></div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="none"
                        >
                          <path
                            fill="#FCAF17"
                            d="m7.35 17.95-5.6-5.6 1.075-1.075L7.35 15.8l1.075 1.075L7.35 17.95Zm4.25 0L6 12.35l1.075-1.075L11.6 15.8l9.6-9.6 1.075 1.075L11.6 17.95Zm0-4.25-1.075-1.075L16.95 6.2l1.075 1.075L11.6 13.7Z"
                          />
                        </svg>
                      </div>

                      {userRole && userRole === "CFO" ? (
                        <div
                          className="flex-1"
                          onClick={() =>
                            userRole && userRole === "CFO"
                              ? moveToCFOTimesheet(data.timesheet, data.type)
                              : null
                          }
                        >
                          <h4 className="mt-1 inline w-full text-lg text-gray-700">
                            {data.Message}
                          </h4>
                          <p className="mt-1 flex items-center space-x-1 truncate text-sm text-gray-500">
                            <span>
                              {moment
                                .utc(data.created_at)
                                .local()
                                .startOf("seconds")
                                .fromNow()}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div
                          className="flex-1"
                          onClick={() => {
                            userRole && userRole !== "Approver"
                              ? moveToTimesheet(
                                `/edit-timesheet/id=${data.timesheet}`
                              )
                              : moveToTimesheet(
                                `/list-timesheet/?id=${data.timesheet}`
                              );
                          }}
                        >
                          <h4 className="mt-1 inline w-full text-lg text-gray-700">
                            {data.Message}
                          </h4>
                          <p className="mt-1 flex items-center space-x-1 truncate text-sm text-gray-500">
                            <span>
                              {moment
                                .utc(data.created_at)
                                .local()
                                .startOf("seconds")
                                .fromNow()}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  ))
                ) : msg_loading ? (
                  <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center pt-3 ">
                    <span className="fa fa-spinner fa-spin "></span>
                  </div>
                ) : (
                  <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center pt-3 ">
                    No Notification Found
                  </div>
                )}
              </li>
            </ul>
            {/* <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">1</span> to{" "}
                  <span className="font-medium">10</span> of{" "}
                  <span className="font-medium">97</span> results
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md"
                  aria-label="Pagination"
                >
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                  <a
                    href="#"
                    aria-current="page"
                    className="relative z-10 hidden items-center rounded-full bg-[#FCAF17] px-4 py-2 text-sm font-semibold text-white lg:inline-flex"
                  >
                    1
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    2
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    3
                  </a>
                  <a
                    href="#"
                    className="relative hidden items-center rounded-full px-4 py-2 text-sm font-semibold text-gray-900 lg:inline-flex"
                  >
                    4
                  </a>
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </nav>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

import { useState } from "react";
import iconUpload from "../assets/icons/uploading-icon.svg";
import iconScan from "../assets/icons/scan-icon.svg";
import UploadingFileProcess from "./UploadFileProcess";

function UploadProcess(props) {
  const [isScan, setIsScan] = useState(false);
  if (props.sending) {
    setTimeout(() => {
      setIsScan(true);
    }, 4000);
  }
  return (
    <section className='upload-process'>
      <img src={isScan ? iconScan : iconUpload} alt='upload icon' />
      <p className='upload-process__title'>Uploading File</p>
      <UploadingFileProcess fileName={props.fileName} fileSize={props.fileSize} filled={props.filled} />
    </section>
  );
}

export default UploadProcess;

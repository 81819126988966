import React, { useEffect, useState } from "react";
import TimeSheetTable from "./components/timeSheet";
import AddNewTimesheet from "./components/addTimnesheet";
import Dashboard from "./components/dashboard";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { TimesheetDataTable } from "./components/timeSheetDataTable";
import { LoginPage } from "./components/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "./store/actions/alert.action";
import { history } from "./helpers/history";
import Upload from "./components/Upload";
import { PrivateRoute } from "./helpers/PrivateRoute";
import Machines from "./components/Machines";
import ViewDocument from "./components/ViewDocument";
import Anomalies from "./components/Anomalies";
import Telematics from "./components/Telematics";
import { ChangePassword } from "./components/changePassword";
import TimeSheetPrefilled from "./components/timeSheetPreFilled";
import EditTimesheet from "./components/EditTimesheet";
import ViewTimesheet from "./components/viewTimesheet";
import TimeSheetTableEdit from "./components/timeSheetEdit";
import AddMachine from "./components/AddMachine";
import Reports from "./components/Reports";
import HelpAndSupport from "./components/shareAndSupport";
import { ShowPdfFile } from "./components/showPdfFile";
// import { LoginPageNew } from "./components/LoginPageNew";
// import AddTimeSheet from "./components/AddTimesheet";
function App() {
  const dispatch = useDispatch();
  const user_dt = useSelector((state) => state.users);
  const currentPath = window.location.pathname;
  const [showMessage, setShowMessage] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    let inactivityTimer;
    setUserRole(
      localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null
    );

    // const resetTimer = () => {
    //   clearTimeout(inactivityTimer);
    //   inactivityTimer = setTimeout(() => {
    //     localStorage.removeItem("token");
    //     history.push("/login");
    //     window.location.href = "/login";
    //   }, 10 * 60 * 1000); // 10 minutes
    // };


    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        localStorage.removeItem("token");
        history.push("/login");
        window.location.href = "/login";
      }, 15 * 60 * 1000); // 15 minutes
    };

    const handleUserActivity = () => {
      if (showMessage) {
        setShowMessage(false);
      }
      resetTimer();
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
    };

    // Clean up the timer on component unmount
  }, []);

  return (
    <div>
      <div>
        {currentPath && currentPath !== "/change-password"
          ? user_dt &&
            user_dt.items !== undefined &&
            user_dt.items.is_uptodate === false
            ? window.location.assign("/change-password?period=expired")
            : null
          : null}

        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginPage} />
            {/* <Route path="/new-login" component={LoginPageNew} /> */}
            <PrivateRoute path="/edit-timesheet" component={EditTimesheet} />
            <PrivateRoute
              exact
              path="/list-timesheet"
              component={ViewTimesheet}
            />
            {userRole && userRole === "Subcontractor" ? (
              <PrivateRoute exact path="/reports" component={Dashboard} />
            ) : (
              <PrivateRoute exact path="/reports" component={Reports} />
            )}

            <PrivateRoute path="/upload" component={Upload} />
            <PrivateRoute path="/save-timesheet" component={AddNewTimesheet} />
            <PrivateRoute path="/machines" component={Machines} />
            <PrivateRoute path="/view-document" component={ViewDocument} />
            <PrivateRoute path="/anomalies" component={Anomalies} />
            <PrivateRoute path="/telematics" component={Telematics} />

            <PrivateRoute path="/change-password" component={ChangePassword} />
            <PrivateRoute path="/add-machine" component={AddMachine} />
            <PrivateRoute path="/help-and-support" component={HelpAndSupport} />
            <PrivateRoute path="/timesheet-pdf" component={ShowPdfFile} />

            <PrivateRoute
              path="/view-timesheet"
              component={TimeSheetPrefilled}
            />
            <PrivateRoute path="/" component={Dashboard} />

            <Redirect from="*" to="/" component={Dashboard} />
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;

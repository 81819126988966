import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBBtn } from "mdb-react-ui-kit";
import { timesheetActions } from "../../store/actions/timeSheet.actions";
import Base64ToImage from "../base64ToImg";
import SignaturePad from "react-signature-canvas";
import { ShowPdfFile } from "../showPdfFile";

export function Modal({ open, setOpen, timesheet_data,selected_sheetid }) {

  
  // const showPdf = (bse64_string) => {
  //   setOpen(false)
  //   const binaryData = atob(bse64_string);
  //   const uint8Array = new Uint8Array(binaryData.length);
  //   for (let i = 0; i < binaryData.length; i++) {
  //     uint8Array[i] = binaryData.charCodeAt(i);
  //   }
  //   const blob = new Blob([uint8Array], { type: "application/pdf" });
  
  //   const pdfUrl = URL.createObjectURL(blob);
  //   const newWindow = window.open('', '_blank');
  //   if (newWindow) {
  //     newWindow.document.write(`
  //       <html>
  //         <head>
  //           <title>PDF Viewer</title>
  //         </head>
  //         <body>
  //           <div class="flex">
  //             <div class="sidebar">Your sidebar content here</div>
  //             <main class="w-full min-w-[90rem] overflow-x-auto bg-gray-100 py-10">
  //               <iframe title="PDF Viewer" src="${pdfUrl}" width="100%" height="100%"></iframe>
  //             </main>
  //           </div>
  //         </body>
  //       </html>
  //     `);
  //   }
  // }
  const openPdfInNewTab = (base64String) => {
    let base_str = base64String.match(/base64,([^,]+)/);
    const base64Data = base_str ? base_str[1] : null;


    const newWindow = window.open('/timesheet-pdf', '_blank');
    if (newWindow) {
      newWindow.pdfData = base64Data; 
    }
  };



  
  const [approveClicked,setApproveClicked] = useState(false)
  const [requrdtClicked,setRequestClicked] = useState(false)
  const sigCanvas = useRef({});

  const dispatch = useDispatch();
  const TimesheetData = timesheet_data ? timesheet_data : null;
  const [userRole, setUserRole] = useState(null);

  const timesheet_submitted_days = TimesheetData ? TimesheetData.days_data.length : `N/A`;
  // console.log("timesheet", TimesheetData.days_data.length)

  const audit_or_approved = useSelector(
    (state) => state.AuditOrApproveSheet.audit_or_approve
  );
  const audit_or_approved_loading = useSelector(
    (state) => state.AuditOrApproveSheet.audit_or_approve_loading
  );
  const timesheet_loading = useSelector(
    (state) => state.timesheet.timesheet_loading
  );
  const [isApproved, setIsApproved] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgCount, setMsgCount] = useState(1);
  const [sheetMessqage, setSheetMessage] = useState(null);
  const [validationMessages, setValidationMessages] = useState([]);
  const [alertMsg, setAlertMsg] = useState(false);

  const submit = (status) => {
    let auditable = false;
    let approved = true;
    if (status === "Audit") {
      auditable = true;
      approved = false;
    }

    if (status === "Approved") {
      auditable = false;
      approved = true;
    }

    let dataDict = {
      sheet_id: TimesheetData.id,
      is_auditable: auditable,
      is_approved: approved,
      messages: sheetMessqage,
    };

    let messages = [];

    if (!sheetMessqage && status === "Audit") {
      messages.push("Message is required");
    }

    if (messages.length > 0) {
      const error_violation = document.getElementById("msg_div");
      window.scrollTo({
        top: error_violation.offsetTop,
        behavior: "smooth",
      });
      setValidationMessages(messages);
    } else {
      setAlertMsg(true);
      setValidationMessages([]);
      setMsgCount(1);
      setShowMsgBox(false);
      setValidationMessages([]);
      dispatch(timesheetActions.timesheetApprovedOrAudit(dataDict));
    }
  };
  const handleCloseBox = () => {
    setShowMsgBox(false);
    setOpen(false);
    setSheetMessage(null);
    if (selected_sheetid ){
    window.location.assign(`/list-timesheet`);}

  };

  const handleOnClick = () => {
    setRequestClicked(true)
    setApproveClicked(false)
    setShowMsgBox(true);
    setMsgCount(2);
    setIsCancel(true);
    if (msgCount === 2) {
      submit("Audit");
    }
    setIsApproved(false);
  };
  const handleOnClick2 = () => {
    setRequestClicked(false)
    setApproveClicked(true)
    setIsCancel(false);
    setShowMsgBox(false);
    submit("Approve");
    setMsgCount(1);
    setIsApproved(true);
    setSheetMessage(null);
  };
  
  useEffect(() => {
    setUserRole(
      localStorage.getItem("user_role")
        ? localStorage.getItem("user_role")
        : null
    );

    
    
  }, [TimesheetData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseBox}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-3xl transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <form>
                
                  <div className="space-y-6">
                    <div>
                      <div className="flex items-center justify-between pb-4">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900">
                          Timesheet Details
                        </h2>
                        <button type="button" onClick={() => handleCloseBox()}>
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>

                      <hr className="-mx-6" />

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-4">
                        <div>
                          <label
                            htmlFor="machine"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="machine"
                              id="machine"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Water Ranker"
                              value={
                                TimesheetData ? TimesheetData.machine : `N/H`
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="machineSubType"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Machine Subtype
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="machineSubType"
                              id="machineSubType"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Machine Subtype"
                              defaultValue={
                                TimesheetData ? TimesheetData.machine_sub_type : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="foreman"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Foreman
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="foreman"
                              id="foreman"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Foreman"
                              value={
                                TimesheetData ? TimesheetData.foreman : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="invoice-number"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Invoice Number
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="invoice-number"
                              id="invoice-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="IN12121"
                              value={
                                TimesheetData ? TimesheetData.invoice : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="purchase-order-number"
                            className="block truncate text-sm leading-6 text-gray-700"
                          >
                            Purchase Order Number
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="purchase-number-order"
                              id="purchase-number-order"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="DW0256"
                              value={TimesheetData ? TimesheetData.po : `N/H`}
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="status"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Status
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              type="text"
                              value={
                                TimesheetData
                                  ? TimesheetData.is_approved === false &&
                                    TimesheetData.is_auditable === false
                                    ? `Pending`
                                    : TimesheetData.is_approved === true &&
                                      TimesheetData.is_auditable === false
                                    ? `Approved`
                                    : `Clarification Requested`
                                  : `N/A`
                              }
                            />
                            {/* <select
                              name="status"
                              id="status"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                            >
                              <option>Approved</option>
                              <option>Clarification Requested</option>
                              <option>Pending</option>
                            </select> */}
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="order-number"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Reference Number
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="order-number"
                              id="order-number"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="456"
                              value={
                                TimesheetData
                                  ? TimesheetData.order_number
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>

                        {/* <div>
                          <label
                            htmlFor="cost-center"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Cost Center
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="cost-center"
                              id="cost-center"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="456"
                              value={
                                TimesheetData
                                  ? TimesheetData.cost_center
                                  : `N/H`
                              }
                            />
                          </div>
                        </div> */}

                        <div>
                          <label
                            htmlFor="total-hours"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Total Hours
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="total-hours"
                              id="total-hours"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="14"
                              value={
                                TimesheetData
                                  ? TimesheetData.total_hours
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="days-per-month"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Days Per Month
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="days-per-month"
                              id="days-per-month"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="31"
                              value={
                                TimesheetData
                                  ? 
                                  // TimesheetData.days_per_month
                                  timesheet_submitted_days
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="per-day"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Average Hours Per Day
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="per-day"
                              id="per-day"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="0.45"
                              value={
                                TimesheetData ? TimesheetData.per_day : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="total-day"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            {TimesheetData && TimesheetData.days_per_month > 1 ? `Total Days` :
                            `Total Day` }
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="total-day"
                              id="total-day"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="14"
                              value={
                                TimesheetData
                                  ? TimesheetData.days_per_month
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="name"
                              id="name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Manager"
                              value={
                                TimesheetData
                                  ? TimesheetData.manager_name
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Vehicle Code
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="name"
                              id="name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Vehicle Code"
                              value={
                                TimesheetData
                                  ? TimesheetData.vehicle_code
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="site_name"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            Site Name
                          </label>
                          <div className="mt-2">
                            <input
                              disabled
                              type="text"
                              name="site_name"
                              id="site_name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              placeholder="Site Name"
                              value={
                                TimesheetData
                                  ? TimesheetData.site_name
                                  : `N/H`
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="site_name"
                            className="block text-sm leading-6 text-gray-700"
                          >
                            File
                          </label>
                          <div className="mt-2">
                            <button
                              type="button"
                              name="new-pdf"
                              id="new-pdf"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              onClick={() => openPdfInNewTab(TimesheetData.pdf_base64)}
                              disabled={!TimesheetData || !TimesheetData.pdf_base64}>
                                {TimesheetData && TimesheetData.pdf_base64 ? `Click here to view file` : `No file`}
                            </button>

                          </div>
                        </div>
                        <div>
                          <label className="block text-sm leading-6 text-gray-700">
                            Signature
                          </label>
                          {TimesheetData && TimesheetData.signature !== null ?
                          <div className="mt-2 view-signature-size">
                          
                            <Base64ToImage
                                base64String={ TimesheetData && TimesheetData.signature !== null && TimesheetData.signature
                              }cls={false}
                            />
                          </div>:null
                          }
                        </div>

                       
                        <div className="col-span-4 flow-root rounded-lg  ">
                            <label
                              htmlFor="Note"
                              className="block text-sm leading-6 text-gray-700"
                            >
                              Note
                            </label>
                            <div className="mt-2">
                              <textarea
                                rows={2}
                                name="Notes"
                                id="Notes"
                                disabled={true}
                                value={
                                  TimesheetData
                                    ? TimesheetData.notes
                                    : null
                                }
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                              ></textarea>
                            </div>
                          </div>

                        <div className="col-span-4 my-6 flow-root rounded-lg border border-gray-200 bg-white">
                          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                              <div className="relative">
                                <table className="min-w-full table-fixed divide-y divide-gray-200">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="border-r border-gray-200 py-3.5 pl-5 pr-3 text-left text-sm font-normal text-gray-400"
                                      >
                                        <div className="flex items-center space-x-1">
                                          <span>Day</span>
                                          <button type="button">
                                            <ChevronDownIcon className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </th>
                                      <th
                                        scope="col"
                                        className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                      >
                                        <div className="flex items-center space-x-1">
                                          <span>Date</span>
                                          <button type="button">
                                            <ChevronDownIcon className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </th>
                                      <th
                                        scope="col"
                                        className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                      >
                                        <div className="flex items-center space-x-1">
                                          <span>Hours</span>
                                          <button type="button">
                                            <ChevronDownIcon className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </th>
                                      <th
                                        scope="col"
                                        className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                      >
                                        <div className="flex items-center space-x-1">
                                          <span>Rate</span>
                                          <button type="button">
                                            <ChevronDownIcon className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </th>
                                      <th
                                        scope="col"
                                        className="border-r border-gray-200 px-3 py-3.5 text-left text-sm font-normal text-gray-400"
                                      >
                                        <div className="flex items-center space-x-1">
                                          <span>Period</span>
                                          <button type="button">
                                            <ChevronDownIcon className="h-4 w-4" />
                                          </button>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {TimesheetData &&
                                      TimesheetData.days_data.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td className="whitespace-nowrap py-4 pl-5 pr-3 text-sm text-gray-500">
                                              {item.day}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              {item.date}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              {item.hours}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              {item.rate}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              {item.period}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  {/* <button
                    type="button"
                    className="focus-visible:outline-bg-[#FCAF17] mt-6 inline-flex w-full justify-center rounded-md bg-[#FCAF17] px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    Save
                  </button> */}

                  {showMsgBox && (
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="signature"
                        className="block text-sm leading-6 text-gray-700"
                      >
                        Message
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={3}
                          name="description"
                          id="description"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FCAF17] sm:text-sm sm:leading-6"
                          placeholder="Type here..."
                          onChange={(e) => setSheetMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  )}
                  <div
                    className="clr-red col-sm-12 col-lg-12 col-xs-12 col-md-12 d-flex justify-content-center mt-20"
                    id="msg_div"
                  >
                    <ul>
                      {validationMessages.map((vm) => (
                        <li key={vm}>{vm}</li>
                      ))}
                    </ul>
                  </div>
                  {alertMsg === true &&
                    audit_or_approved === true &&
                    isCancel === false &&
                    audit_or_approved_loading === false &&
                    isApproved === true && (
                      <div>
                        <p className="success-msg ">
                          Timesheet Approved Successfully
                        </p>
                      </div>
                    )}

                  {alertMsg === true &&
                    isCancel === true &&
                    audit_or_approved === true &&
                    audit_or_approved_loading === false &&
                    isApproved === false && (
                      <div>
                        <p className="warn-msg">Clarification Requested</p>
                      </div>
                    )}
                </form>
                
                
                <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12   row mt-20">
                  <div className="col-sm-12 col-lg-2 col-xs-12 col-md-2"></div>
                  <div className="col-sm-12 col-lg-9 col-xs-12 col-md-9  row">
                    {userRole && userRole === "Approver" ? (
                      <>
                        <div className="col-sm-6 col-lg-6 col-xs-6 col-md-6 d-flex mx-auto">
                          {TimesheetData &&
                          TimesheetData.is_approved === false ? (
                            <MDBBtn
                              onClick={
                                () => handleOnClick()
                                // submit("Audit"),
                                // setIsApproved(false)
                              }
                              className={"audit-and-approve-btn"}
                            >
                              {audit_or_approved_loading && requrdtClicked &&(
                                <span className="fa fa-spinner fa-spin"></span>
                              )}
                              {timesheet_loading &&  requrdtClicked && (
                                <span className="fa fa-spinner fa-spin"></span>
                              )}{" "}
                              Request Clarification
                            </MDBBtn>
                          ) : null}
                        </div>
                        <div className="col-sm-4 col-lg-4 col-xs-4 col-md-4 d-flex mx-auto">
                          {TimesheetData &&
                          TimesheetData.is_approved === false ? (
                            <MDBBtn
                              onClick={() => handleOnClick2()}
                              className={"audit-and-approve-btn"}
                            >
                              {audit_or_approved_loading && approveClicked &&(
                                <span className="fa fa-spinner fa-spin"></span>
                              )}
                              {timesheet_loading && approveClicked && (
                                <span className="fa fa-spinner fa-spin"></span>
                              )}{" "}
                              Approve
                            </MDBBtn>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

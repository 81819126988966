import { DocumentService } from "../../services/document.service";
import { documentConstants } from "../actions/types/document.constants";

export const documentActions = {
  getDocumentlist,
};

function getDocumentlist(
  fileType,
  companyType,
  foremanType,
  machineType,
  page,
  start_date,
  end_date,
  year,
  doc_search
) {
  return (dispatch) => {
    dispatch(request());
    DocumentService.getDocumentlist(
      fileType,
      companyType,
      foremanType,
      machineType,
      page,
      start_date,
      end_date,
      year,
      doc_search
    ).then(
      (document_list) => dispatch(success(document_list)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: documentConstants.GET_DOCUMENT_REQUEST };
  }
  function success(document_list) {
    if (document_list.code === 200) {
      return {
        type: documentConstants.GET_DOCUMENT_SUCCESS,
        document_list,
      };
    } else {
      return {
        type: documentConstants.GET_DOCUMENT_FAILURE,
        document_list,
      };
    }
  }
  function failure(error) {
    return { type: documentConstants.GET_DOCUMENT_FAILURE, error };
  }
}
